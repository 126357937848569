import {
  API_GET_CURRENCY,
  API_GET_CURRENCY_SUCCESS,
  API_GET_CURRENCY_ERROR,
  API_GET_PROVIDERS,
  API_GET_PROVIDERS_SUCCESS,
  API_GET_PROVIDERS_ERROR,
  API_GET_EMPLOYEES,
  API_GET_EMPLOYEES_SUCCESS,
  API_GET_EMPLOYEES_ERROR,
  API_SAVE_PROVIDER,
  API_SAVE_PROVIDER_SUCCESS,
  API_SAVE_PROVIDER_ERROR,
  API_UPDATE_PROVIDER_ERROR,
  API_DELETE_PROVIDER_ERROR,
  API_UPDATE_PROVIDER,
  API_DELETE_PROVIDER,
  API_DELETE_PROVIDER_SUCCESS,
  API_UPDATE_PROVIDER_SUCCESS,
  API_SAVE_BANK,
  API_SAVE_BANK_SUCCESS,
  API_SAVE_BANK_ERROR,
  API_UPDATE_BANK,
  API_UPDATE_BANK_SUCCESS,
  API_UPDATE_BANK_ERROR,
  API_DELETE_BANK,
  API_DELETE_BANK_SUCCESS,
  API_DELETE_BANK_ERROR,
  
  API_SAVE_CURRENCY,
  API_SAVE_CURRENCY_SUCCESS,
  API_SAVE_CURRENCY_ERROR,
  API_UPDATE_CURRENCY,
  API_UPDATE_CURRENCY_SUCCESS,
  API_UPDATE_CURRENCY_ERROR,
  API_DELETE_CURRENCY,
  API_DELETE_CURRENCY_SUCCESS,
  API_DELETE_CURRENCY_ERROR,
} from "../actions";

//#region BANKS

export const saveBank = (bank) => ({
  type: API_SAVE_BANK,
  payload: { bank },
});
export const saveBankSuccess = (bank) => ({
  type: API_SAVE_BANK_SUCCESS,
  payload: bank,
});
export const saveBankError = (message) => ({
  type: API_SAVE_BANK_ERROR,
  payload: { message },
});

export const updateBank = (bank) => ({
  type: API_UPDATE_BANK,
  payload: { bank },
});
export const updateBankSuccess = (bank) => ({
  type: API_UPDATE_BANK_SUCCESS,
  payload: bank,
});
export const updateBankError = (message) => ({
  type: API_UPDATE_BANK_ERROR,
  payload: { message },
});

export const deleteBank = (bank) => ({
  type: API_DELETE_BANK,
  payload: { bank },
});
export const deleteBankSuccess = (bank) => ({
  type: API_DELETE_BANK_SUCCESS,
  payload: { bank },
});
export const deleteBankError = (message) => ({
  type: API_DELETE_BANK_ERROR,
  payload: { message },
});
//#endregion

//#region CURRENCIES
export const getCurrencies = (user) => ({
  type: API_GET_CURRENCY,
  payload: { user },
});

export const getCurrenciesSuccess = (currencies) => ({
  type: API_GET_CURRENCY_SUCCESS,
  payload: currencies,
});
export const getCurrenciesError = (message) => ({
  type: API_GET_CURRENCY_ERROR,
  payload: { message },
});
export const saveCurrency = (currency) => ({
  type: API_SAVE_CURRENCY,
  payload: { currency },
});
export const saveCurrencySuccess = (currency) => ({
  type: API_SAVE_CURRENCY_SUCCESS,
  payload: currency,
});
export const saveCurrencyError = (message) => ({
  type: API_SAVE_CURRENCY_ERROR,
  payload: { message },
});

export const updateCurrency = (currency) => ({
  type: API_UPDATE_CURRENCY,
  payload: { currency },
});
export const updateCurrencySuccess = (currency) => ({
  type: API_UPDATE_CURRENCY_SUCCESS,
  payload: currency,
});
export const updateCurrencyError = (message) => ({
  type: API_UPDATE_CURRENCY_ERROR,
  payload: { message },
});

export const deleteCurrency = (currency) => ({
  type: API_DELETE_CURRENCY,
  payload: { currency },
});
export const deleteCurrencySuccess = (currency) => ({
  type: API_DELETE_CURRENCY_SUCCESS,
  payload: { currency },
});
export const deleteCurrencyError = (message) => ({
  type: API_DELETE_CURRENCY_ERROR,
  payload: { message },
});
//#endregion

//#region PROVIDERS
export const getProviders = (user) => ({
  type: API_GET_PROVIDERS,
  payload: { user },
});
export const getProvidersSuccess = (providers) => ({
  type: API_GET_PROVIDERS_SUCCESS,
  payload: providers,
});
export const getProvidersError = (message) => ({
  type: API_GET_PROVIDERS_ERROR,
  payload: { message },
});

export const saveProvider = (provider) => ({
  type: API_SAVE_PROVIDER,
  payload: { provider },
});
export const saveProviderSuccess = (provider) => ({
  type: API_SAVE_PROVIDER_SUCCESS,
  payload: provider,
});
export const saveProviderError = (message) => ({
  type: API_SAVE_PROVIDER_ERROR,
  payload: { message },
});

export const updateProvider = (provider) => ({
  type: API_UPDATE_PROVIDER,
  payload: { provider },
});
export const updateProviderSuccess = (provider) => ({
  type: API_UPDATE_PROVIDER_SUCCESS,
  payload: provider,
});
export const updateProviderError = (message) => ({
  type: API_UPDATE_PROVIDER_ERROR,
  payload: { message },
});

export const deleteProvider = (provider) => ({
  type: API_DELETE_PROVIDER,
  payload: { provider },
});
export const deleteProviderSuccess = (provider) => ({
  type: API_DELETE_PROVIDER_SUCCESS,
  payload: provider,
});
export const deleteProviderError = (message) => ({
  type: API_DELETE_PROVIDER_ERROR,
  payload: { message },
});
//#endregion

export const getEmployees = (user) => ({
  type: API_GET_EMPLOYEES,
  payload: { user },
});
export const getEmployeesSuccess = (employees) => ({
  type: API_GET_EMPLOYEES_SUCCESS,
  payload: employees,
});
export const getEmployeesError = (message) => ({
  type: API_GET_EMPLOYEES_ERROR,
  payload: { message },
});
