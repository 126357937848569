import {
  API_GET_PROVIDERS,
  API_GET_PROVIDERS_ERROR,
  API_GET_PROVIDERS_SUCCESS,
  API_GET_CURRENCY,
  API_GET_CURRENCY_SUCCESS,
  API_GET_CURRENCY_ERROR,
  API_SAVE_CURRENCY,
  API_SAVE_CURRENCY_SUCCESS,
  API_SAVE_CURRENCY_ERROR,
  API_UPDATE_CURRENCY,
  API_UPDATE_CURRENCY_SUCCESS,
  API_UPDATE_CURRENCY_ERROR,
  API_DELETE_CURRENCY,
  API_DELETE_CURRENCY_SUCCESS,
  API_DELETE_CURRENCY_ERROR,
  API_SAVE_CLIENT,
  API_SAVE_CLIENT_SUCCESS,
  API_SAVE_CLIENT_ERROR,
  API_GET_EMPLOYEES_ERROR,
  API_SAVE_PROVIDER,
  API_SAVE_PROVIDER_SUCCESS,
  API_SAVE_PROVIDER_ERROR,
  API_UPDATE_PROVIDER,
  API_UPDATE_PROVIDER_ERROR,
  API_UPDATE_PROVIDER_SUCCESS,
  API_DELETE_PROVIDER_ERROR,
  API_DELETE_PROVIDER,
  API_DELETE_PROVIDER_SUCCESS,
  API_SAVE_BANK,
  API_SAVE_BANK_SUCCESS,
  API_SAVE_BANK_ERROR,
  API_UPDATE_BANK,
  API_UPDATE_BANK_SUCCESS,
  API_UPDATE_BANK_ERROR,
  API_DELETE_BANK,
  API_DELETE_BANK_SUCCESS,
  API_DELETE_BANK_ERROR,
} from "../actions";

const INIT_STATE = {
  currencies: [],
  providers: [],
  employees: [],
  loading: false,
  error: "",
  success: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region  BANKS

    case API_SAVE_BANK:
      return { ...state, loading: true, error: "", success: false };
    case API_SAVE_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_SAVE_BANK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };

    case API_UPDATE_BANK:
      return { ...state, loading: true, error: "", succes: false };
    case API_UPDATE_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_UPDATE_BANK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };

    case API_DELETE_BANK:
      return { ...state, loading: true, error: "", success: false };
    case API_DELETE_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_DELETE_BANK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };
    //#endregion

    //#region CURRENCY
    case API_GET_CURRENCY:
      return { ...state, loading: true, error: "", success: false };
    case API_GET_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        currencies: action.payload,
      };
    case API_GET_CURRENCY_ERROR:
      return {
        ...state,
        loading: false,
        currencies: [],
        error: action.payload.message,
      };
    case API_SAVE_CURRENCY:
      return { ...state, loading: true, error: "" };
    case API_SAVE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_SAVE_CURRENCY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };
    case API_UPDATE_CURRENCY:
      return { ...state, loading: true, error: "", succes: false };
    case API_UPDATE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_UPDATE_CURRENCY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };

    case API_DELETE_CURRENCY:
      return { ...state, loading: true, error: "", success: false };
    case API_DELETE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_DELETE_CURRENCY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };
    //#endregion

    //#region  PROVIDERS
    case API_GET_PROVIDERS:
      return { ...state, loading: true, error: "", success: false };
    case API_GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        providers: action.payload,
      };
    case API_GET_PROVIDERS_ERROR:
      return {
        ...state,
        loading: false,
        providers: [],
        error: action.payload.message,
      };

    case API_SAVE_PROVIDER:
      return { ...state, loading: true, error: "", success: false };
    case API_SAVE_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_SAVE_PROVIDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };

    case API_UPDATE_PROVIDER:
      return { ...state, loading: true, error: "", succes: false };
    case API_UPDATE_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_UPDATE_PROVIDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };

    case API_DELETE_PROVIDER:
      return { ...state, loading: true, error: "", success: false };
    case API_DELETE_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_DELETE_PROVIDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };
    //#endregion

   

    case API_SAVE_CLIENT:
      return { ...state, loading: true, error: "" };
    case API_SAVE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case API_SAVE_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };
    // case API_UPDATE_CLIENT:
    //   return { ...state, loading: true, error: "" };
    // case API_UPDATE_CLIENT_SUCCESS:
    // case API_UPDATE_CLIENT_ERROR:
    // case API_DELETE_CLIENT:
    //   return { ...state, loading: true, error: "" };
    // case API_DELETE_CLIENT_SUCCESS:
    // case API_DELETE_CLIENT_ERROR:

    // case API_GET_EMPLOYEES:
    //   return { ...state, loading: true, error: "" };
    // case API_GET_EMPLOYEES_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: "",
    //     employees: action.payload
    //   };
    case API_GET_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        employees: [],
        error: action.payload.message,
      };

    default:
      return { ...state };
  }
};
