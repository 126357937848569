//#region LOGOUT
export const API_GET_USER_LOGOUT = "API_GET_USER_LOGOUT";
export const API_GET_USER_LOGOUT_SUCCESS = "API_GET_USER_LOGOUT_SUCCESS";
export const API_GET_USER_LOGOUT_FAIL = "API_GET_USER_LOGOUT_FAIL";
export const logoutUser = (user) => ({
  type: API_GET_USER_LOGOUT,
  payload: {
    request: {
      method: "DELETE",
      url: "/access",
      data: {
        user,
      },
    },
  },
});
//#endregion

//#region  LOGIN
export const API_LOGIN_USER = "API_LOGIN_USER";
export const API_LOGIN_USER_SUCCESS = "API_LOGIN_USER_SUCCESS";
export const API_LOGIN_USER_FAIL = "API_LOGIN_USER_FAIL";
export const loginUser = ({ email: user, password }) => ({
  type: API_LOGIN_USER,
  payload: {
    request: {
      method: "POST",
      url: "/access",
      data: {
        user,
        password,
      },
    },
  },
});
//#endregion

//#region DEPARTMENTS
export const API_GET_DEPARTMENTS = "API_GET_DEPARTMENTS";
export const API_GET_DEPARTMENTS_SUCCESS = "API_GET_DEPARTMENTS_SUCCESS";
export const API_GET_DEPARTMENTS_FAIL = "API_GET_DEPARTMENTS_FAIL";
export const getDepartments = (user) => ({
  type: API_GET_DEPARTMENTS,
  payload: {
    request: {
      method: "GET",
      url: "/department",
      data: {
        user,
      },
    },
  },
});
export const API_SAVE_DEPARTMENT = "API_SAVE_DEPARTMENT";
export const API_SAVE_DEPARTMENT_SUCCESS = "API_SAVE_DEPARTMENT_SUCCESS";
export const API_SAVE_DEPARTMENT_FAIL = "API_SAVE_DEPARTMENT_FAIL";
export const saveDepartment = (value) => ({
  type: API_SAVE_DEPARTMENT,
  payload: {
    request: {
      method: "POST",
      url: "/department",
      data: value,
    },
  },
});
export const API_UPDATE_DEPARTMENT = "API_UPDATE_DEPARTMENT";
export const API_UPDATE_DEPARTMENT_SUCCESS = "API_UPDATE_DEPARTMENT_SUCCESS";
export const API_UPDATE_DEPARTMENT_FAIL = "API_UPDATE_DEPARTMENT_FAIL";
export const updateDepartment = (value) => {
  return {
    type: API_UPDATE_DEPARTMENT,
    payload: {
      request: {
        method: "PUT",
        url: "/department/" + value.correlative,
        data: value,
      },
    },
  };
};
export const API_DELETE_DEPARTMENT = "API_DELETE_DEPARTMENT";
export const API_DELETE_DEPARTMENT_SUCCESS = "API_DELETE_DEPARTMENT_SUCCESS";
export const API_DELETE_DEPARTMENT_FAIL = "API_DELETE_DEPARTMENT_FAIL";
export const deleteDepartment = (data) => {
  return {
    type: API_DELETE_DEPARTMENT,
    payload: {
      request: {
        method: "DELETE",
        url: "/department/" + data.correlative,
        data: data,
      },
    },
  };
};
//#endregion
//#region CLIENTS
export const API_GET_CLIENTS = "API_GET_CLIENTS";
export const API_GET_CLIENTS_SUCCESS = "API_GET_CLIENTS_SUCCESS";
export const API_GET_CLIENTS_FAIL = "API_GET_CLIENTS_FAIL";
export const getClients = (user) => ({
  type: API_GET_CLIENTS,
  payload: {
    request: {
      method: "GET",
      url: "/client",
      data: {
        user,
      },
    },
  },
});
export const API_SAVE_CLIENT = "API_SAVE_CLIENT";
export const API_SAVE_CLIENT_SUCCESS = "API_SAVE_CLIENT_SUCCESS";
export const API_SAVE_CLIENT_FAIL = "API_SAVE_CLIENT_FAIL";
export const saveClient = (value) => ({
  type: API_SAVE_CLIENT,
  payload: {
    request: {
      method: "POST",
      url: "/client",
      data: value,
    },
  },
});
export const API_UPDATE_CLIENT = "API_UPDATE_CLIENT";
export const API_UPDATE_CLIENT_SUCCESS = "API_UPDATE_CLIENT_SUCCESS";
export const API_UPDATE_CLIENT_FAIL = "API_UPDATE_CLIENT_FAIL";
export const updateClient = (value) => {
  return {
    type: API_UPDATE_CLIENT,
    payload: {
      request: {
        method: "PUT",
        url: "/client/" + value.correlative,
        data: value,
      },
    },
  };
};
export const API_DELETE_CLIENT = "API_DELETE_CLIENT";
export const API_DELETE_CLIENT_SUCCESS = "API_DELETE_CLIENT_SUCCESS";
export const API_DELETE_CLIENT_FAIL = "API_DELETE_CLIENT_FAIL";
export const deleteClient = (data) => {
  return {
    type: API_DELETE_CLIENT,
    payload: {
      request: {
        method: "DELETE",
        url: "/client/" + data.correlative,
        data: data,
      },
    },
  };
};
//#endregion
//#region PROVIDERS
export const API_GET_PROVIDERS = "API_GET_PROVIDERS";
export const API_GET_PROVIDERS_SUCCESS = "API_GET_PROVIDERS_SUCCESS";
export const API_GET_PROVIDERS_FAIL = "API_GET_PROVIDERS_FAIL";
export const getProviders = (user) => ({
  type: API_GET_PROVIDERS,
  payload: {
    request: {
      method: "GET",
      url: "/provider",
      data: {
        user,
      },
    },
  },
});
export const API_SAVE_PROVIDER = "API_SAVE_PROVIDER";
export const API_SAVE_PROVIDER_SUCCESS = "API_SAVE_PROVIDER_SUCCESS";
export const API_SAVE_PROVIDER_FAIL = "API_SAVE_PROVIDER_FAIL";
export const saveProvider = (value) => ({
  type: API_SAVE_PROVIDER,
  payload: {
    request: {
      method: "POST",
      url: "/provider",
      data: value,
    },
  },
});
export const API_UPDATE_PROVIDER = "API_UPDATE_PROVIDER";
export const API_UPDATE_PROVIDER_SUCCESS = "API_UPDATE_PROVIDER_SUCCESS";
export const API_UPDATE_PROVIDER_FAIL = "API_UPDATE_PROVIDER_FAIL";
export const updateProvider = (value) => {
  return {
    type: API_UPDATE_PROVIDER,
    payload: {
      request: {
        method: "PUT",
        url: "/provider/" + value.correlative,
        data: value,
      },
    },
  };
};
export const API_DELETE_PROVIDER = "API_DELETE_PROVIDER";
export const API_DELETE_PROVIDER_SUCCESS = "API_DELETE_PROVIDER_SUCCESS";
export const API_DELETE_PROVIDER_FAIL = "API_DELETE_PROVIDER_FAIL";
export const deleteProvider = (data) => {
  return {
    type: API_DELETE_PROVIDER,
    payload: {
      request: {
        method: "DELETE",
        url: "/provider/" + data.correlative,
        data: data,
      },
    },
  };
};
//#endregion
//#region ACCOUNT CATEGORIES
export const API_GET_ACCOUNT_CATEGORIES = "API_GET_ACCOUNT_CATEGORIES";
export const API_GET_ACCOUNT_CATEGORIES_SUCCESS =
  "API_GET_ACCOUNT_CATEGORIES_SUCCESS";
export const API_GET_ACCOUNT_CATEGORIES_FAIL =
  "API_GET_ACCOUNT_CATEGORIES_FAIL";
export const getAccountCategories = (user) => ({
  type: API_GET_ACCOUNT_CATEGORIES,
  payload: {
    request: {
      method: "GET",
      url: "/expensecat",
      data: {
        user,
      },
    },
  },
});
export const API_SAVE_ACCOUNT_CATEGORY = "API_SAVE_ACCOUNT_CATEGORY";
export const API_SAVE_ACCOUNT_CATEGORY_SUCCESS =
  "API_SAVE_ACCOUNT_CATEGORY_SUCCESS";
export const API_SAVE_ACCOUNT_CATEGORY_FAIL = "API_SAVE_ACCOUNT_CATEGORY_FAIL";
export const saveAccountCategory = (expensecat) => ({
  type: API_SAVE_ACCOUNT_CATEGORY,
  payload: {
    request: {
      method: "POST",
      url: "/expensecat",
      data: expensecat,
    },
  },
});
export const API_UPDATE_ACCOUNT_CATEGORY = "API_UPDATE_ACCOUNT_CATEGORY";
export const API_UPDATE_ACCOUNT_CATEGORY_SUCCESS =
  "API_UPDATE_ACCOUNT_CATEGORY_SUCCESS";
export const API_UPDATE_ACCOUNT_CATEGORY_FAIL =
  "API_UPDATE_ACCOUNT_CATEGORY_FAIL";
export const updateAccountCategory = (account) => {
  return {
    type: API_UPDATE_ACCOUNT_CATEGORY,
    payload: {
      request: {
        method: "PUT",
        url: "/expensecat/" + account.correlative,
        data: account,
      },
    },
  };
};
export const API_DELETE_ACCOUNT_CATEGORY = "API_DELETE_ACCOUNT_CATEGORY";
export const API_DELETE_ACCOUNT_CATEGORY_SUCCESS =
  "API_DELETE_ACCOUNT_CATEGORY_SUCCESS";
export const API_DELETE_ACCOUNT_CATEGORY_FAIL =
  "API_DELETE_ACCOUNT_CATEGORY_FAIL";
export const deleteAccountCategory = (account) => {
  return {
    type: API_DELETE_ACCOUNT_CATEGORY,
    payload: {
      request: {
        method: "DELETE",
        url: "/expensecat/" + account.correlative,
        data: account,
      },
    },
  };
};
//#endregion
//#region BANKS
export const API_GET_BANKS = "API_GET_BANKS";
export const API_GET_BANKS_SUCCESS = "API_GET_BANKS_SUCCESS";
export const API_GET_BANKS_FAIL = "API_GET_BANKS_FAIL";
export const getBanks = (user) => ({
  type: API_GET_BANKS,
  payload: {
    request: {
      method: "GET",
      url: "/bank",
      data: {
        user,
      },
    },
  },
});
export const API_SAVE_BANK = "API_SAVE_BANK";
export const API_SAVE_BANK_SUCCESS = "API_SAVE_BANK_SUCCESS";
export const API_SAVE_BANK_FAIL = "API_SAVE_BANK_FAIL";
export const saveBank = (value) => ({
  type: API_SAVE_BANK,
  payload: {
    request: {
      method: "POST",
      url: "/bank",
      data: value,
    },
  },
});
export const API_UPDATE_BANK = "API_UPDATE_BANK";
export const API_UPDATE_BANK_SUCCESS = "API_UPDATE_BANK_SUCCESS";
export const API_UPDATE_BANK_FAIL = "API_UPDATE_DBANKFAIL";
export const updateBank = (value) => {
  return {
    type: API_UPDATE_BANK,
    payload: {
      request: {
        method: "PUT",
        url: "/bank/" + value.correlative,
        data: value,
      },
    },
  };
};
export const API_DELETE_BANK = "API_DELETE_BANK";
export const API_DELETE_BANK_SUCCESS = "API_DELETE_BANK_SUCCESS";
export const API_DELETE_BANK_FAIL = "API_DELETE_BANK_FAIL";
export const deleteBank = (data) => {
  return {
    type: API_DELETE_BANK,
    payload: {
      request: {
        method: "DELETE",
        url: "/bank/" + data.correlative,
        data: data,
      },
    },
  };
};
//#endregion
//#region CURRENCIES
export const API_GET_CURRENCIES = "API_GET_CURRENCIES";
export const API_GET_CURRENCIES_SUCCESS = "API_GET_CURRENCIES_SUCCESS";
export const API_GET_CURRENCIES_FAIL = "API_GET_CURRENCIES_FAIL";
export const getCurrencies = (user) => ({
  type: API_GET_CURRENCIES,
  payload: {
    request: {
      method: "GET",
      url: "/currency",
      data: {
        user,
      },
    },
  },
});
//#endregion

//#region PAYMENT_TYPES
export const API_GET_PAYMENT_TYPES = "API_GET_PAYMENT_TYPES";
export const API_GET_PAYMENT_TYPES_SUCCESS = "API_GET_PAYMENT_TYPES_SUCCESS";
export const API_GET_PAYMENT_TYPES_FAIL = "API_GET_PAYMENT_TYPES_FAIL";
export const getPaymentTypes = (user) => ({
  type: API_GET_PAYMENT_TYPES,
  payload: {
    request: {
      method: "GET",
      url: "/paytype",
      data: {
        user,
      },
    },
  },
});

export const API_SAVE_PAYMENT_TYPE = "API_SAVE_PAYMENT_TYPE";
export const API_SAVE_PAYMENT_TYPE_SUCCESS = "API_SAVE_PAYMENT_TYPE_SUCCESS";
export const API_SAVE_PAYMENT_TYPE_FAIL = "API_SAVE_PAYMENT_TYPE_FAIL";
export const savePaymentType = (value) => ({
  type: API_SAVE_PAYMENT_TYPE,
  payload: {
    request: {
      method: "POST",
      url: "/paytype",
      data: value,
    },
  },
});
export const API_UPDATE_PAYMENT_TYPE = "API_UPDATE_PAYMENT_TYPE";
export const API_UPDATE_PAYMENT_TYPE_SUCCESS =
  "API_UPDATE_PAYMENT_TYPE_SUCCESS";
export const API_UPDATE_PAYMENT_TYPE_FAIL = "API_UPDATE_PAYMENT_TYPE_FAIL";
export const updatePaymentType = (value) => {
  return {
    type: API_UPDATE_PAYMENT_TYPE,
    payload: {
      request: {
        method: "PUT",
        url: "/paytype/" + value.correlative,
        data: value,
      },
    },
  };
};
export const API_DELETE_PAYMENT_TYPE = "API_DELETE_PAYMENT_TYPE";
export const API_DELETE_PAYMENT_TYPE_SUCCESS =
  "API_DELETE_PAYMENT_TYPE_SUCCESS";
export const API_DELETE_PAYMENT_TYPE_FAIL = "API_DELETE_PAYMENT_TYPE_FAIL";
export const deletePaymentType = (data) => {
  return {
    type: API_DELETE_PAYMENT_TYPE,
    payload: {
      request: {
        method: "DELETE",
        url: "/paytype/" + data.correlative,
        data: data,
      },
    },
  };
};
//#endregion

//#region PAYORDERS

export const API_GET_PAYORDERS = "API_GET_PAYORDERS";
export const API_GET_PAYORDERS_SUCCESS = "API_GET_PAYORDERS_SUCCESS";
export const API_GET_PAYORDERS_FAIL = "API_GET_PAYORDERS_FAIL";
export const getPayOrders = (user) => ({
  type: API_GET_PAYORDERS,
  payload: {
    request: {
      method: "GET",
      url: "/payorders",
      data: {
        user,
      },
    },
  },
});

export const API_GET_PAYORDER_BY_ID = "API_GET_PAYORDER_BY_ID";
export const API_GET_PAYORDER_BY_ID_SUCCESS = "API_GET_PAYORDER_BY_ID_SUCCESS";
export const API_GET_PAYORDER_BY_ID_FAIL = "API_GET_PAYORDER_BY_ID_FAIL";
export const getPayorderById = (payorder_id) => ({
  type: API_GET_PAYORDER_BY_ID,
  payload: {
    request: {
      method: "GET",
      url: "/payorders/" + payorder_id,
      data: {},
    },
  },
});

export const API_SAVE_PAYORDER = "API_SAVE_PAYORDER";
export const API_SAVE_PAYORDER_SUCCESS = "API_SAVE_PAYORDER_SUCCESS";
export const API_SAVE_PAYORDER_FAIL = "API_SAVE_PAYORDER_FAIL";
export const savePayOrder = (payorders) => ({
  type: API_SAVE_PAYORDER,
  payload: {
    request: {
      method: "POST",
      url: "/payorders",
      data: payorders,
    },
  },
});

export const API_UPDATE_PAYORDER = "API_UPDATE_PAYORDER";
export const API_UPDATE_PAYORDER_SUCCESS = "API_UPDATE_PAYORDER_SUCCESS";
export const API_UPDATE_PAYORDER_FAIL = "API_UPDATE_PAYORDER_FAIL";
export const updatePayOrder = (payorders) => {
  return {
    type: API_UPDATE_PAYORDER,
    payload: {
      request: {
        method: "PUT",
        url: "/payorders/" + payorders[0].correlative,
        data: payorders,
      },
    },
  };
};

export const API_DELETE_PAYORDER = "API_DELETE_PAYORDER";
export const API_DELETE_PAYORDER_SUCCESS = "API_DELETE_PAYORDER_SUCCESS";
export const API_DELETE_PAYORDER_FAIL = "API_DELETE_PAYORDER_FAIL";
export const deletePayOrder = (payorder) => {
  console.log(payorder);
  return {
    type: API_DELETE_PAYORDER,
    payload: {
      request: {
        method: "DELETE",
        url: "/payorders/" + payorder[0].correlative,
        data: payorder,
      },
    },
  };
};

//#endregion

//#region SUBCREDITS SUBORDINACIONES

export const API_GET_SUBORDINATIONS = "API_GET_SUBORDINATIONS";
export const API_GET_SUBORDINATIONS_SUCCESS = "API_GET_SUBORDINATIONS_SUCCESS";
export const API_GET_SUBORDINATIONS_FAIL = "API_GET_SUBORDINATIONS_FAIL";
export const getSubordinations = (user) => ({
  type: API_GET_SUBORDINATIONS,
  payload: {
    request: {
      method: "GET",
      url: "/subordination",
      data: {
        user,
      },
    },
  },
});

export const API_GET_SUBORDINATION_BY_ID = "API_GET_SUBORDINATION_BY_ID";
export const API_GET_SUBORDINATION_BY_ID_SUCCESS =
  "API_GET_SUBORDINATION_BY_ID_SUCCESS";
export const API_GET_SUBORDINATION_BY_ID_FAIL =
  "API_GET_SUBORDINATION_BY_ID_FAIL";
export const getSubordinationById = (subordination) => ({
  type: API_GET_SUBORDINATION_BY_ID,
  payload: {
    request: {
      method: "GET",
      url: "/subcredit/" + subordination,
      data: {},
    },
  },
});

export const API_SAVE_SUBORDINATION = "API_SAVE_SUBORDINATION";
export const API_SAVE_SUBORDINATION_SUCCESS = "API_SAVE_SUBORDINATION_SUCCESS";
export const API_SAVE_SUBORDINATION_FAIL = "API_SAVE_SUBORDINATION_FAIL";
export const saveSubordination = (subordination) => ({
  type: API_SAVE_SUBORDINATION,
  payload: {
    request: {
      method: "POST",
      url: "/subordination",
      data: subordination,
    },
  },
});

export const API_GET_SUBCREDITS = "API_GET_SUBCREDITS";
export const API_GET_SUBCREDITS_SUCCESS = "API_GET_SUBCREDITS_SUCCESS";
export const API_GET_SUBCREDITS_FAIL = "API_GET_SUBCREDITS_FAIL";
export const getSubCredits = (user) => ({
  type: API_GET_SUBCREDITS,
  payload: {
    request: {
      method: "GET",
      url: "/subcredit",
      data: {
        user,
      },
    },
  },
});

export const API_GET_SUBCREDITS_BY_ID = "API_GET_SUBCREDITS_BY_ID";
export const API_GET_SUBCREDITS_BY_ID_SUCCESS =
  "API_GET_SUBCREDITS_BY_ID_SUCCESS";
export const API_GET_SUBCREDITS_BY_ID_FAIL = "API_GET_SUBCREDITS_BY_ID_FAIL";
export const getSubCreditsById = (subordination) => ({
  type: API_GET_SUBCREDITS_BY_ID,
  payload: {
    request: {
      method: "GET",
      url: "/subcredit/" + subordination,
      data: {},
    },
  },
});

export const API_SAVE_SUBCREDIT = "API_SAVE_SUBCREDIT";
export const API_SAVE_SUBCREDIT_SUCCESS = "API_SAVE_SUBCREDIT_SUCCESS";
export const API_SAVE_SUBCREDIT_FAIL = "API_SAVE_SUBCREDIT_FAIL";
export const saveSubCredit = (subcredit) => ({
  type: API_SAVE_SUBCREDIT,
  payload: {
    request: {
      method: "POST",
      url: "/subcredit",
      data: subcredit,
    },
  },
});

//#endregion

//#region EXPENSES
export const API_GET_EXPENSES = "API_GET_EXPENSES";
export const API_GET_EXPENSES_SUCCESS = "API_GET_EXPENSES_SUCCESS";
export const API_GET_EXPENSES_FAIL = "API_GET_EXPENSES_FAIL";
export const getExpenses = (user) => ({
  type: API_GET_EXPENSES,
  payload: {
    request: {
      method: "GET",
      url: "/expense",
      data: {
        user,
      },
    },
  },
});

export const API_SAVE_EXPENSE = "API_SAVE_EXPENSE";
export const API_SAVE_EXPENSE_SUCCESS = "API_SAVE_EXPENSE_SUCCESS";
export const API_SAVE_EXPENSE_FAIL = "API_SAVE_EXPENSE_FAIL";
export const saveExpense = (expense) => ({
  type: API_SAVE_EXPENSE,
  payload: {
    request: {
      method: "POST",
      url: "/expense",
      data: expense,
    },
  },
});

//#endregion

//#region BANK MOVEMENTS

export const API_GET_BANK_MOVEMENTS = "API_GET_BANK_MOVEMENTS";
export const API_GET_BANK_MOVEMENTS_SUCCESS = "API_GET_BANK_MOVEMENTS_SUCCESS";
export const API_GET_BANK_MOVEMENTS_FAIL = "API_GET_BANK_MOVEMENTS_FAIL";
export const getBankMoves = (user) => ({
  type: API_GET_BANK_MOVEMENTS,
  payload: {
    request: {
      method: "GET",
      url: "/bankmovement",
      data: {
        user,
      },
    },
  },
});

export const API_SAVE_BANK_MOVEMENT = "API_SAVE_BANK_MOVEMENT";
export const API_SAVE_BANK_MOVEMENT_SUCCESS = "API_SAVE_BANK_MOVEMENT_SUCCESS";
export const API_SAVE_BANK_MOVEMENT_FAIL = "API_SAVE_BANK_MOVEMENT_FAIL";
export const saveBankMovement = (expense) => ({
  type: API_SAVE_BANK_MOVEMENT,
  payload: {
    request: {
      method: "POST",
      url: "/bankmovement",
      data: expense,
    },
  },
});

export const API_DELETE_BANK_MOVEMENT = "API_DELETE_BANK_MOVEMENT";
export const API_DELETE_BANK_MOVEMENT_SUCCESS =
  "API_DELETE_BANK_MOVEMENT_SUCCESS";
export const API_DELETE_BANK_MOVEMENT_FAIL = "API_DELETE_BANK_MOVEMENT_FAIL";
export const deleteBankMovement = (data) => {
  return {
    type: API_DELETE_BANK_MOVEMENT,
    payload: {
      request: {
        method: "DELETE",
        url: "/bankmovement/" + data.id,
        data: { user: data.user },
      },
    },
  };
};

//#endregion

//#region INTERBANK MOVEMENTS
export const API_SAVE_INTER_BANK_MOVEMENT = "API_SAVE_INTER_BANK_MOVEMENT";
export const API_SAVE_INTER_BANK_MOVEMENT_SUCCESS =
  "API_SAVE_INTER_BANK_MOVEMENT_SUCCESS";
export const API_SAVE_INTER_BANK_MOVEMENT_FAIL =
  "API_SAVE_INTER_BANK_MOVEMENT_FAIL";
export const saveInterbankMovement = (expense) => ({
  type: API_SAVE_INTER_BANK_MOVEMENT,
  payload: {
    request: {
      method: "POST",
      url: "/internal.movement",
      data: expense,
    },
  },
});

//#endregion

//#region DISTRIBUTIONS
export const API_GET_DISTRIBUTIONS = "API_GET_DISTRIBUTIONS";
export const API_GET_DISTRIBUTIONS_SUCCESS = "API_GET_DISTRIBUTIONS_SUCCESS";
export const API_GET_DISTRIBUTIONS_FAIL = "API_GET_DISTRIBUTIONS_FAIL";
export const getDistributions = (user) => ({
  type: API_GET_DISTRIBUTIONS,
  payload: {
    request: {
      method: "GET",
      url: "/distribution",
      data: {
        user,
      },
    },
  },
});

export const API_GET_DISTRIBUTION_PARTNERS = "API_GET_DISTRIBUTION_PARTNERS";
export const API_GET_DISTRIBUTION_PARTNERS_SUCCESS =
  "API_GET_DISTRIBUTION_PARTNERS_SUCCESS";
export const API_GET_DISTRIBUTION_PARTNERS_FAIL =
  "API_GET_DISTRIBUTION_PARTNERS_FAIL";
export const getDistributionPartners = (user) => ({
  type: API_GET_DISTRIBUTION_PARTNERS,
  payload: {
    request: {
      method: "GET",
      url: "/partner.payment",
      data: {
        user,
      },
    },
  },
});
export const API_GET_DISTRIBUTION_PARTNER_BY_ID =
  "API_GET_DISTRIBUTION_PARTNER_BY_ID";
export const API_GET_DISTRIBUTION_PARTNER_BY_ID_SUCCESS =
  "API_GET_DISTRIBUTION_PARTNER_BY_ID_SUCCESS";
export const API_GET_DISTRIBUTION_PARTNER_BY_ID_FAIL =
  "API_GET_DISTRIBUTION_PARTNER_BY_ID_FAIL";
export const getDistributionPartnerByID = (id) => ({
  type: API_GET_DISTRIBUTION_PARTNER_BY_ID,
  payload: {
    request: {
      method: "GET",
      url: "/partner.payment/" + id,
      data: {},
    },
  },
});

export const SET_DISTRIBUTION_PARTNER_BY_ID = "SET_DISTRIBUTION_PARTNER_BY_ID";
export const setSelectedDistributionPartnerByID = (data) => {
  console.log("SET DATA", data);
  return {
    type: SET_DISTRIBUTION_PARTNER_BY_ID,
    payload: data,
  };
};
//#endregion

//#region DISTRIBUTIONS PORTFOLIO
export const API_GET_DISTRIBUTIONS_PORTFOLIO =
  "API_GET_DISTRIBUTIONS_PORTFOLIO";
export const API_GET_DISTRIBUTIONS_PORTFOLIO_SUCCESS =
  "API_GET_DISTRIBUTIONS_PORTFOLIO_SUCCESS";
export const API_GET_DISTRIBUTIONS_PORTFOLIO_FAIL =
  "API_GET_DISTRIBUTIONS_PORTFOLIO_FAIL";
export const getDistributionsPortfolio = (user) => ({
  type: API_GET_DISTRIBUTIONS_PORTFOLIO,
  payload: {
    request: {
      method: "GET",
      url: "/distribution.portfolio",
      data: {
        user,
      },
    },
  },
});
export const API_SAVE_DISTRIBUTIONS_PORTFOLIO =
  "API_SAVE_DISTRIBUTIONS_PORTFOLIO";
export const API_SAVE_DISTRIBUTIONS_PORTFOLIO_SUCCESS =
  "API_SAVE_DISTRIBUTIONS_PORTFOLIO_SUCCESS";
export const API_SAVE_DISTRIBUTIONS_PORTFOLIO_FAIL =
  "API_SAVE_DISTRIBUTIONS_PORTFOLIO_FAIL";
export const saveDistributionsPortfolio = (distribution) => ({
  type: API_SAVE_DISTRIBUTIONS_PORTFOLIO,
  payload: {
    request: {
      method: "POST",
      url: "/distribution.portfolio",
      data: distribution,
    },
  },
});
export const API_DELETE_DISTRIBUTIONS_PORTFOLIO =
  "API_DELETE_DISTRIBUTIONS_PORTFOLIO";
export const API_DELETE_DISTRIBUTIONS_PORTFOLIO_SUCCESS =
  "API_DELETE_DISTRIBUTIONS_PORTFOLIO_SUCCESS";
export const API_DELETE_DISTRIBUTIONS_PORTFOLIO_FAIL =
  "API_DELETE_DISTRIBUTIONS_PORTFOLIO_FAIL";
export const deleteDistributionPortfolio = (data) => {
  return {
    type: API_DELETE_DISTRIBUTIONS_PORTFOLIO,
    payload: {
      request: {
        method: "DELETE",
        url: "/distribution.portfolio/" + data.correlative,
        data: data,
      },
    },
  };
};

export const API_GET_DISTRIBUTION_PORTFOLIO_BY_ID =
  "API_GET_DISTRIBUTION_PORTFOLIO_BY_ID";
export const API_GET_DISTRIBUTION_PORTFOLIO_BY_ID_SUCCESS =
  "API_GET_DISTRIBUTION_PORTFOLIO_BY_ID_SUCCESS";
export const API_GET_DISTRIBUTION_PORTFOLIO_BY_ID_FAIL =
  "API_GET_DISTRIBUTION_PORTFOLIO_BY_ID_FAIL";
export const getDistributionPortfolioByID = (payorder_id) => ({
  type: API_GET_DISTRIBUTION_PORTFOLIO_BY_ID,
  payload: {
    request: {
      method: "GET",
      url: "/distribution.portfolio/" + payorder_id,
      data: {},
    },
  },
});

//#endregion

//#region  DISTRIBUTION PORTFOLIO WALLET
export const API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET =
  "API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET";
export const API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET_SUCCESS =
  "API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET_SUCCESS";
export const API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET_FAIL =
  "API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET_FAIL";
export const saveDistributionPortfolioWallet = (wallet) => ({
  type: API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET,
  payload: {
    request: {
      method: "POST",
      url: "/distribution.wallet",
      data: wallet,
    },
  },
});
//#endregion

//#region DISTRIBUTION WALLET MONITORING
export const API_GET_YEAR_MONITORING = "API_GET_YEAR_MONITORING";
export const API_GET_YEAR_MONITORING_SUCCESS =
  "API_GET_YEAR_MONITORING_SUCCESS";
export const API_GET_YEAR_MONITORING_FAIL = "API_GET_YEAR_MONITORING_FAIL";
export const getYearMonitoring = (data) => ({
  type: API_GET_YEAR_MONITORING,
  payload: {
    request: {
      method: "GET",
      url: "/year.monitoring",
      data: {
        data,
      },
    },
  },
});

export const API_GET_MONITORING_CALC = "API_GET_MONITORING_CALC";
export const API_GET_MONITORING_CALC_SUCCESS =
  "API_GET_MONITORING_CALC_SUCCESS";
export const API_GET_MONITORING_CALC_FAIL = "API_GET_MONITORING_CALC_FAIL";
export const getCalcMonitoring = (data) => {
  console.log("actions", JSON.stringify(data, null, 2));
  return {
    type: API_GET_MONITORING_CALC,
    payload: {
      request: {
        method: "POST",
        url: "/distribution.wallet.monitoring.calc",
        data: data,
      },
    },
  };
};

//#endregion
//#region ALLOCATIONS
export const API_GET_ALLOCATIONS = "API_GET_ALLOCATIONS";
export const API_GET_ALLOCATIONS_SUCCESS = "API_GET_ALLOCATIONS_SUCCESS";
export const API_GET_ALLOCATIONS_FAIL = "API_GET_ALLOCATIONS_FAIL";
export const getAllocations = (user, id) => ({
  type: API_GET_ALLOCATIONS,
  payload: {
    request: {
      method: "GET",
      url: "/allocate/" + id,
      data: {
        user,
      },
    },
  },
});

//#endregion

//#region INVOICES
export const API_GET_INVOICES = "API_GET_INVOICES";
export const API_GET_INVOICES_SUCCESS = "API_GET_INVOICES_SUCCESS";
export const API_GET_INVOICES_FAIL = "API_GET_INVOICES_FAIL";
export const getInvoices = (user) => ({
  type: API_GET_INVOICES,
  payload: {
    request: {
      method: "GET",
      url: "/billing",
      data: {
        user,
      },
    },
  },
});
export const API_SAVE_INVOICE = "API_SAVE_INVOICE";
export const API_SAVE_INVOICE_SUCCESS = "API_SAVE_INVOICE_SUCCESS";
export const API_SAVE_INVOICE_FAIL = "API_SAVE_INVOICE_FAIL";
export const saveInvoice = (payorders) => ({
  type: API_SAVE_INVOICE,
  payload: {
    request: {
      method: "POST",
      url: "/billing",
      data: payorders,
    },
  },
});
export const API_UPDATE_INVOICE = "API_UPDATE_INVOICE";
export const API_UPDATE_INVOICE_SUCCESS = "API_UPDATE_INVOICE_SUCCESS";
export const API_UPDATE_INVOICE_FAIL = "API_UPDATE_INVOICE_FAIL";
export const updateInvoice = (value) => {
  return {
    type: API_UPDATE_INVOICE,
    payload: {
      request: {
        method: "PUT",
        url: "/billing/" + value.correlative,
        data: value,
      },
    },
  };
};
//#endregion

//#region ACOUNTS RECEIVABLE
export const API_GET_ACCOUNTS_RECEIVABLE = "API_GET_ACCOUNTS_RECEIVABLE";
export const API_GET_ACCOUNTS_RECEIVABLE_SUCCESS =
  "API_GET_ACCOUNTS_RECEIVABLE_SUCCESS";
export const API_GET_ACCOUNTS_RECEIVABLE_FAIL =
  "API_GET_ACCOUNTS_RECEIVABLE_FAIL";
export const getAccountsReceivable = (user) => ({
  type: API_GET_ACCOUNTS_RECEIVABLE,
  payload: {
    request: {
      method: "GET",
      url: "/accreceivable",
      data: {
        user,
      },
    },
  },
});

export const API_SAVE_ACCOUNTS_RECEIVABLE = "API_SAVE_ACCOUNTS_RECEIVABLE";
export const API_SAVE_ACCOUNTS_RECEIVABLE_SUCCESS =
  "API_SAVE_ACCOUNTS_RECEIVABLE_SUCCESS";
export const API_SAVE_ACCOUNTS_RECEIVABLE_FAIL =
  "API_SAVE_ACCOUNTS_RECEIVABLE_FAIL";
export const saveAccountReceivable = (invoice_payment) => ({
  type: API_SAVE_ACCOUNTS_RECEIVABLE,
  payload: {
    request: {
      method: "POST",
      url: "/accreceivable",
      data: invoice_payment,
    },
  },
});

export const API_GET_ACCOUNT_RECEIVABLE = "API_GET_ACCOUNT_RECEIVABLE";
export const API_GET_ACCOUNT_RECEIVABLE_SUCCESS =
  "API_GET_ACCOUNT_RECEIVABLE_SUCCESS";
export const API_GET_ACCOUNT_RECEIVABLE_FAIL =
  "API_GET_ACCOUNT_RECEIVABLE_FAIL";
export const getAccountReceivableById = (id) => ({
  type: API_GET_ACCOUNT_RECEIVABLE,
  payload: {
    request: {
      method: "GET",
      url: "/accreceivable/" + id,
      data: {},
    },
  },
});

export const API_UPDATE_ACCOUNT_RECEIVABLE = "API_UPDATE_ACCOUNT_RECEIVABLE";
export const API_UPDATE_ACCOUNT_RECEIVABLE_SUCCESS =
  "API_UPDATE_ACCOUNT_RECEIVABLE_SUCCESS";
export const API_UPDATE_ACCOUNT_RECEIVABLE_FAIL =
  "API_UPDATE_ACCOUNT_RECEIVABLE_FAIL";
export const updateAccountReceivable = (value) => {
  return {
    type: API_UPDATE_ACCOUNT_RECEIVABLE,
    payload: {
      request: {
        method: "PUT",
        url: "/accreceivable/" + value.correlative,
        data: value,
      },
    },
  };
};

export const API_DELETE_ACCOUNT_RECEIVABLE = "API_DELETE_ACCOUNT_RECEIVABLE";
export const API_DELETE_ACCOUNT_RECEIVABLE_SUCCESS =
  "API_DELETE_ACCOUNT_RECEIVABLE_SUCCESS";
export const API_DELETE_ACCOUNT_RECEIVABLE_FAIL =
  "API_DELETE_ACCOUNT_RECEIVABLE_FAIL";
export const deleteAccountReceivable = (data) => {
  return {
    type: API_DELETE_ACCOUNT_RECEIVABLE,
    payload: {
      request: {
        method: "DELETE",
        url: "/accreceivable/" + data.correlative,
        data: data,
      },
    },
  };
};

//#endregion

//#region INVOICE_PAYMENTS
export const API_GET_INVOICE_PAYMENTS = "API_GET_INVOICE_PAYMENTS";
export const API_GET_INVOICE_PAYMENTS_SUCCESS =
  "API_GET_INVOICE_PAYMENTS_SUCCESS";
export const API_GET_INVOICE_PAYMENTS_FAIL = "API_GET_INVOICE_PAYMENTS_FAIL";
export const getInvoicesPayments = (user) => ({
  type: API_GET_INVOICE_PAYMENTS,
  payload: {
    request: {
      method: "GET",
      url: "/invoicepayment",
      data: {
        user,
      },
    },
  },
});

export const API_GET_INVOICE_PAYMENT = "API_GET_INVOICE_PAYMENT";
export const API_GET_INVOICE_PAYMENT_SUCCESS =
  "API_GET_INVOICE_PAYMENT_SUCCESS";
export const API_GET_INVOICE_PAYMENT_FAIL = "API_GET_INVOICE_PAYMENT_FAIL";
export const getInvoicePaymentById = (invoice) => ({
  type: API_GET_INVOICE_PAYMENT,
  payload: {
    request: {
      method: "GET",
      url: "/invoicepayment/" + invoice,
      data: {},
    },
  },
});
export const API_SAVE_INVOICE_PAYMENT = "API_SAVE_INVOICE_PAYMENT";
export const API_SAVE_INVOICE_PAYMENT_SUCCESS =
  "API_SAVE_INVOICE_PAYMENT_SUCCESS";
export const API_SAVE_INVOICE_PAYMENT_FAIL = "API_SAVE_INVOICE_PAYMENT_FAIL";
export const saveInvoicePayment = (invoice_payment) => ({
  type: API_SAVE_INVOICE_PAYMENT,
  payload: {
    request: {
      method: "POST",
      url: "/invoicepayment",
      data: invoice_payment,
    },
  },
});

export const API_DELETE_INVOICE_PAYMENT = "API_DELETE_INVOICE_PAYMENT";
export const API_DELETE_INVOICE_PAYMENT_SUCCESS =
  "API_DELETE_INVOICE_PAYMENT_SUCCESS";
export const API_DELETE_INVOICE_PAYMENT_FAIL =
  "API_DELETE_INVOICE_PAYMENT_FAIL";
export const deleteInvoicePaymentById = (invoice) => ({
  type: API_DELETE_INVOICE_PAYMENT,
  payload: {
    request: {
      method: "DELETE",
      url: "/invoicepayment/" + invoice.correlative,
      data: invoice,
    },
  },
});

//#endregion

//#region PAYABLE_ACCOUNTS
export const API_GET_PAYABLE_ACCOUNTS = "API_GET_PAYABLE_ACCOUNTS";
export const API_GET_PAYABLE_ACCOUNTS_SUCCESS =
  "API_GET_PAYABLE_ACCOUNTS_SUCCESS";
export const API_GET_PAYABLE_ACCOUNTS_FAIL = "API_GET_PAYABLE_ACCOUNTS_FAIL";
export const getPayableAccounts = (user) => ({
  type: API_GET_PAYABLE_ACCOUNTS,
  payload: {
    request: {
      method: "GET",
      url: "/accountpayable",
      data: {
        user,
      },
    },
  },
});
export const API_SAVE_PAYABLE_ACCOUNT = "API_SAVE_PAYABLE_ACCOUNT";
export const API_SAVE_PAYABLE_ACCOUNT_SUCCESS =
  "API_SAVE_PAYABLE_ACCOUNT_SUCCESS";
export const API_SAVE_PAYABLE_ACCOUNT_FAIL = "API_SAVE_PAYABLE_ACCOUNT_FAIL";
export const savePayableAccount = (payable_account) => ({
  type: API_SAVE_PAYABLE_ACCOUNT,
  payload: {
    request: {
      method: "POST",
      url: "/accountpayable",
      data: payable_account,
    },
  },
});

export const API_GET_PAYABLE_ACCOUNT = "API_GET_PAYABLE_ACCOUNT";
export const API_GET_PAYABLE_ACCOUNT_SUCCESS =
  "API_GET_PAYABLE_ACCOUNT_SUCCESS";
export const API_GET_PAYABLE_ACCOUNT_FAIL = "API_GET_PAYABLE_ACCOUNT_FAIL";
export const getPayableAccountById = (payable_account_id) => ({
  type: API_GET_PAYABLE_ACCOUNT,
  payload: {
    request: {
      method: "GET",
      url: "/invoicetopay/" + payable_account_id,
      data: {},
    },
  },
});

export const API_SAVE_PAYABLE_ACCOUNT_PAYMENT =
  "API_SAVE_PAYABLE_ACCOUNT_PAYMENT";
export const API_SAVE_PAYABLE_ACCOUNT_PAYMENT_SUCCESS =
  "API_SAVE_PAYABLE_ACCOUNT_PAYMENT_SUCCESS";
export const API_SAVE_PAYABLE_ACCOUNT_PAYMENT_FAIL =
  "API_SAVE_PAYABLE_ACCOUNT_PAYMENT_FAIL";
export const savePayableAccountPayment = (data) => ({
  type: API_SAVE_PAYABLE_ACCOUNT_PAYMENT,
  payload: {
    request: {
      method: "POST",
      url: "/invoicetopay",
      data,
    },
  },
});
export const API_DELETE_PAYABLE_ACCOUNT_PAYMENT =
  "API_DELETE_PAYABLE_ACCOUNT_PAYMENT";
export const API_DELETE_PAYABLE_ACCOUNT_PAYMENT_SUCCESS =
  "API_DELETE_PAYABLE_ACCOUNT_PAYMENT_SUCCESS";
export const API_DELETE_PAYABLE_ACCOUNT_PAYMENT_FAIL =
  "API_DELETE_PAYABLE_ACCOUNT_PAYMENT_FAIL";
export const deletePayableAccountPayment = (data) => {
  return {
    type: API_DELETE_PAYABLE_ACCOUNT_PAYMENT,
    payload: {
      request: {
        method: "DELETE",
        url: "/invoicetopay/" + data.correlative,
        data: data,
      },
    },
  };
};

export const API_GET_PAYABLE_ACCOUNT_PAYMENT =
  "API_GET_PAYABLE_ACCOUNT_PAYMENT";
export const API_GET_PAYABLE_ACCOUNT_PAYMENT_SUCCESS =
  "API_GET_PAYABLE_ACCOUNT_PAYMENT_SUCCESS";
export const API_GET_PAYABLE_ACCOUNT_PAYMENT_FAIL =
  "API_GET_PAYABLE_ACCOUNT_PAYMENT_FAIL";
export const getPayableAccountPayments = (payable_account_id) => ({
  type: API_GET_PAYABLE_ACCOUNT_PAYMENT,
  payload: {
    request: {
      method: "GET",
      url: "/invoicetopay/",
      data: {},
    },
  },
});
//#endregion

//#region PAYROLL
export const API_GET_PAYROLLS = "API_GET_PAYROLLS";
export const API_GET_PAYROLLS_SUCCESS = "API_GET_PAYROLLS_SUCCESS";
export const API_GET_PAYROLLS_FAIL = "API_GET_PAYROLLS_FAIL";
export const getPayrolls = (user) => ({
  type: API_GET_PAYROLLS,
  payload: {
    request: {
      method: "GET",
      url: "/payroll",
      data: {
        user,
      },
    },
  },
});
export const API_GET_PAYROLL_BY_ID = "API_GET_PAYROLL_BY_ID";
export const API_GET_PAYROLL_BY_ID_SUCCESS = "API_GET_PAYROLL_BY_ID_SUCCESS";
export const API_GET_PAYROLL_BY_ID_FAIL = "API_GET_PAYROLL_BY_ID_FAIL";
export const getPayrollById = (id) => ({
  type: API_GET_PAYROLL_BY_ID,
  payload: {
    request: {
      method: "GET",
      url: "/payroll/" + id,
      data: {},
    },
  },
});

//#endregion

//#region DASHBOARD
export const API_GET_DASHBOARD = "API_GET_DASHBOARD";
export const API_GET_DASHBOARD_SUCCESS = "API_GET_DASHBOARD_SUCCESS";
export const API_GET_DASHBOARD_FAIL = "API_GET_DASHBOARD_FAIL";
export const getDashboard = (user) => ({
  type: API_GET_DASHBOARD,
  payload: {
    request: {
      method: "GET",
      url: "/dashboard",
      data: { user },
    },
  },
});
//#endregion

//#region WITHHOLDINGS
export const API_GET_WITHHOLDINGS = "API_GET_WITHHOLDINGS";
export const API_GET_WITHHOLDINGS_SUCCESS = "API_GET_WITHHOLDINGS_SUCCESS";
export const API_GET_WITHHOLDINGS_FAIL = "API_GET_WITHHOLDINGS_FAIL";
export const getWithholdings = (user) => ({
  type: API_GET_WITHHOLDINGS,
  payload: {
    request: {
      method: "GET",
      url: "/withholding",
      data: { user },
    },
  },
});
//#endregion

//#region REFUNDS
export const API_GET_REFUNDS = "API_GET_REFUNDS";
export const API_GET_REFUNDS_SUCCESS = "API_GET_REFUNDS_SUCCESS";
export const API_GET_REFUNDS_FAIL = "API_GET_REFUNDS_FAIL";
export const getRefunds = (user) => ({
  type: API_GET_REFUNDS,
  payload: {
    request: {
      method: "GET",
      url: "/refund",
      data: { user },
    },
  },
});
export const API_SAVE_REFUND = "API_SAVE_REFUND";
export const API_SAVE_REFUND_SUCCESS = "API_SAVE_REFUND_SUCCESS";
export const API_SAVE_REFUND_FAIL = "API_SAVE_REFUND_FAIL";
export const saveRefund = (refund) => ({
  type: API_SAVE_REFUND,
  payload: {
    request: {
      method: "POST",
      url: "/refund",
      data: refund,
    },
  },
});
//#endregion

//#region DOCUMENTS RECEIVED
export const API_GET_DOCUMENTS = "API_GET_DOCUMENTS";
export const API_GET_DOCUMENTS_SUCCESS = "API_GET_DOCUMENTS_SUCCESS";
export const API_GET_DOCUMENTS_FAIL = "API_GET_DOCUMENTS_FAIL";
export const getDocuments = (user) => ({
  type: API_GET_DOCUMENTS,
  payload: {
    request: {
      method: "GET",
      url: "/invoice",
      data: { user },
    },
  },
});
//#endregion

//#region BUDGET
export const API_GET_BUDGETS = "API_GET_BUDGETS";
export const API_GET_BUDGETS_SUCCESS = "API_GET_BUDGETS_SUCCESS";
export const API_GET_BUDGETS_FAIL = "API_GET_BUDGETS_FAIL";
export const getBudgets = (user) => ({
  type: API_GET_BUDGETS,
  payload: {
    request: {
      method: "GET",
      url: "/budget",
      data: {
        user,
      },
    },
  },
});

export const API_GET_BUDGET_DETAIL = "API_GET_BUDGET_DETAIL";
export const API_GET_BUDGET_DETAIL_SUCCESS = "API_GET_BUDGET_DETAIL_SUCCESS";
export const API_GET_BUDGET_DETAIL_FAIL = "API_GET_BUDGET_DETAIL_FAIL";
export const getBudgetDetail = (id) => ({
  type: API_GET_BUDGET_DETAIL,
  payload: {
    request: {
      method: "GET",
      url: "/budget/" + id,
      data: {},
    },
  },
});

export const API_SAVE_BUDGET = "API_SAVE_BUDGET";
export const API_SAVE_BUDGET_SUCCESS = "API_SAVE_BUDGET_SUCCESS";
export const API_SAVE_BUDGET_FAIL = "API_SAVE_BUDGET_FAIL";
export const saveBudget = (value) => ({
  type: API_SAVE_BUDGET,
  payload: {
    request: {
      method: "POST",
      url: "/budget",
      data: value,
    },
  },
});

export const API_UPDATE_BUDGET = "API_UPDATE_BUDGET";
export const API_UPDATE_BUDGET_SUCCESS = "API_UPDATE_BUDGET_SUCCESS";
export const API_UPDATE_BUDGET_FAIL = "API_UPDATE_BUDGET_FAIL";
export const updateBudget = (value) => {
  return {
    type: API_UPDATE_BUDGET,
    payload: {
      request: {
        method: "PUT",
        url: "/budget/" + value[0].correlative,
        data: value,
      },
    },
  };
};

export const API_DELETE_BUDGET = "API_DELETE_BUDGET";
export const API_DELETE_BUDGET_SUCCESS = "API_DELETE_BUDGET_SUCCESS";
export const API_DELETE_BUDGET_FAIL = "API_DELETE_BUDGET_FAIL";
export const deleteBudget = (data) => {
  return {
    type: API_DELETE_BUDGET,
    payload: {
      request: {
        method: "DELETE",
        url: "/budget/" + data[0].correlative,
        data: data,
      },
    },
  };
};
//#endregion
