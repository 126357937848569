import * as Actions from "./actions";
import Cookies from "js-cookie";
import { PURGE } from "redux-persist";

const initialState = {
  user_logged: null,
  session: "",
  dashboard: null,
  payment_types: [],
  departments: [],
  clients: [],
  providers: [],
  account_categories: [],
  banks: [],
  currencies: [],
  payorders: [],
  last_payorder_saved: 0,
  selected_payorder: null,
  subordinations: [],
  selected_subordination: null,
  subcredits: [],
  expenses: [],
  banks_movements: [],
  allocations: [],
  year_monitoring: [],
  last_calculated_monitoring: [],
  distributions: [],
  distributions_portfolio: [],
  selected_distribution_portfolio: {},
  distribution_partners: [],
  selected_distribution_by_partner: {},
  invoices: [],
  accounts_receivable: [],
  invoices_payments: [],
  selected_account_receivable: null,
  payable_accounts: [],
  selected_payable_account: null,
  payrolls: [],
  withholdings: [],
  refunds: [],
  documents_received: [],
  selected_payroll: null,
  budgets: [],
  selected_budget: null,
  loading: false,
  error: "",
  success: false,
};

let response = "";
const handleLogin = (action) => {
  response = action.payload.data.response;
  console.log("Login", response);
  if (response?.db?.typ === "success" && Cookies.get("sessionId")) {
    return {
      user_logged: { ...response.data },
      session: Cookies.get("sessionId"),
      error: "",
    };
  } else {
    return {
      error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
    };
  }
};

const getResponseAPI = (api_response, state, key) => {
  //console.log(key, api_response.payload.data.response);
  response = api_response.payload.data.response;
  console.log(response);
  return response?.db?.typ === "success"
    ? {
        ...state,
        loading: false,
        [key]: response.data,
        error: "",
      }
    : {
        ...state,
        [key]: [],
        loading: false,
        success: false,
        error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
      };
};

const loadinDataState = (state, key = "") => {
  return {
    ...state,
    loading: true,
    error: "",
    success: false,
    [key]: null,
  };
};

const handleAPIError = (action) => {
  try {
    return {
      error: action.error.response.data.response.db.msg,
    };
  } catch (error) {
    return {
      error: action.error.data || "Error Desconocido",
    };
  }
};

const reducer_api2 = (state = initialState, action) => {
  response = "";

  switch (action.type) {
    //#region  USER_LOGIN
    case Actions.API_LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_LOGIN_USER_SUCCESS:
      console.log(Actions.API_LOGIN_USER_SUCCESS);
      return {
        ...state,
        ...handleLogin(action),
        loading: false,
      };
    case Actions.API_LOGIN_USER_FAIL:
      // console.log(Actions.API_LOGIN_USER_FAIL);
      return {
        ...handleAPIError(action, state, "error"),
        loading: false,
        user_logged: "",
        session: "",
      };
    //#endregion
    //#region USER_LOGOUT
    case Actions.API_GET_USER_LOGOUT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_GET_USER_LOGOUT_SUCCESS:
      console.log(action);
      return { ...state, loading: false, error: "", logout: true };
    case Actions.API_GET_USER_LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
        logout: true,
      };
    //#endregion
    //#region PAYMENT_TYPES
    case Actions.API_GET_PAYMENT_TYPES:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_PAYMENT_TYPES_SUCCESS:
      return getResponseAPI(action, state, "payment_types");
    case Actions.API_GET_PAYMENT_TYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case Actions.API_SAVE_PAYMENT_TYPE:
      return {
        ...state,
        loading: true,
        error: "",
        payment_types: [],
      };
    case Actions.API_SAVE_PAYMENT_TYPE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_PAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_PAYMENT_TYPE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_PAYMENT_TYPE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_PAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_PAYMENT_TYPE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_PAYMENT_TYPE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_PAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };
    //#endregion
    //#region DEPARTMENTS
    case Actions.API_GET_DEPARTMENTS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_DEPARTMENTS_SUCCESS:
      return getResponseAPI(action, state, "departments");
    case Actions.API_GET_DEPARTMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_DEPARTMENT:
      return {
        ...state,
        loading: true,
        error: "",
        departments: [],
      };
    case Actions.API_SAVE_DEPARTMENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_DEPARTMENT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_DEPARTMENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_DEPARTMENT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_DEPARTMENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion
    //#region CLIENTS
    case Actions.API_GET_CLIENTS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_CLIENTS_SUCCESS:
      return getResponseAPI(action, state, "clients");
    case Actions.API_GET_CLIENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_CLIENT:
      return {
        ...state,
        loading: true,
        error: "",
        clients: [],
      };
    case Actions.API_SAVE_CLIENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_CLIENT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_CLIENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_CLIENT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_CLIENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion

    //#region PROVIDERS
    case Actions.API_GET_PROVIDERS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_PROVIDERS_SUCCESS:
      return getResponseAPI(action, state, "providers");
    case Actions.API_GET_PROVIDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_PROVIDER:
      return {
        ...state,
        loading: true,
        error: "",
        providers: [],
      };
    case Actions.API_SAVE_PROVIDER_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_PROVIDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_PROVIDER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_PROVIDER_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_PROVIDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_PROVIDER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_PROVIDER_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_PROVIDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion
    //#region PAYORDER
    case Actions.API_GET_PAYORDERS:
      return {
        ...state,
        loading: true,
        error: "",
        success: false,
        payorders: [],
      };
    case Actions.API_GET_PAYORDERS_SUCCESS:
      return getResponseAPI(action, state, "payorders");
    case Actions.API_GET_PAYORDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
        payorders: [],
      };

    case Actions.API_GET_PAYORDER_BY_ID:
      return {
        ...loadinDataState(state),
        selected_payorder: null,
      };
    case Actions.API_GET_PAYORDER_BY_ID_SUCCESS:
      return getResponseAPI(action, state, "selected_payorder");
    case Actions.API_GET_PAYORDER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
        selected_payorder: null,
      };

    case Actions.API_SAVE_PAYORDER:
      return {
        ...state,
        loading: true,
        error: "",
        payorders: [],
      };
    case Actions.API_SAVE_PAYORDER_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            last_payorder_saved: response?.db?.odpid,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_PAYORDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_PAYORDER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_PAYORDER_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_PAYORDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_PAYORDER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_PAYORDER_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_PAYORDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };
    //#endregion

    //#region SUBCREDITS SUBORDINACIONES
    case Actions.API_GET_SUBORDINATIONS:
      return {
        ...state,
        loading: true,
        error: "",
        success: false,
        subcredits: [],
        selected_subordination: null,
      };
    case Actions.API_GET_SUBORDINATIONS_SUCCESS:
      return getResponseAPI(action, state, "subordinations");
    case Actions.API_GET_SUBORDINATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_SUBORDINATION:
      return {
        ...state,
        loading: true,
        error: "",
        subordinations: [],
      };
    case Actions.API_SAVE_SUBORDINATION_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_SUBORDINATION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_GET_SUBORDINATION_BY_ID:
      console.log("Get", action);
      return { selected_subordination: null, ...loadinDataState(state) };
    case Actions.API_GET_SUBORDINATION_BY_ID_SUCCESS:
      console.log("Success", action);
      return getResponseAPI(action, state, "selected_subordination");
    case Actions.API_GET_SUBORDINATION_BY_ID_FAIL:
      console.log("Error", action);
      return {
        ...state,
        loading: false,
        error: action.error.message,
        selected_subordination: null,
      };

    case Actions.API_GET_SUBCREDITS:
      return {
        ...state,
        loading: true,
        error: "",
        success: false,
        subcredits: [],
      };
    case Actions.API_GET_SUBCREDITS_SUCCESS:
      return getResponseAPI(action, state, "subcredits");
    case Actions.API_GET_SUBCREDITS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_SUBCREDIT:
      return {
        ...state,
        loading: true,
        error: "",
        subordinations: [],
      };
    case Actions.API_SAVE_SUBCREDIT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_SUBCREDIT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion

    //#region ACCOUNT CATEGORIES
    case Actions.API_GET_ACCOUNT_CATEGORIES:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_ACCOUNT_CATEGORIES_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            account_categories: response.data,
            error: "",
          }
        : {
            ...state,
            account_categories: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_ACCOUNT_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_ACCOUNT_CATEGORY:
      return {
        ...state,
        loading: true,
        error: "",
        account_categories: [],
      };
    case Actions.API_SAVE_ACCOUNT_CATEGORY_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_ACCOUNT_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_ACCOUNT_CATEGORY:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_ACCOUNT_CATEGORY_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_ACCOUNT_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_ACCOUNT_CATEGORY:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_ACCOUNT_CATEGORY_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_ACCOUNT_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion
    //#region BANKS
    case Actions.API_GET_BANKS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_BANKS_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            banks: response.data,
            error: "",
          }
        : {
            ...state,
            banks: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_BANKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_BANK:
      return {
        ...state,
        loading: true,
        error: "",
        banks: [],
      };
    case Actions.API_SAVE_BANK_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_BANK_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_BANK:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_BANK_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_BANK_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_BANK:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_BANK_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_BANK_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion
    //#region CURRENCIES
    case Actions.API_GET_CURRENCIES:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_CURRENCIES_SUCCESS:
      return getResponseAPI(action, state, "currencies");
    case Actions.API_GET_CURRENCIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    //#endregion
    //#region EXPENSES
    case Actions.API_GET_EXPENSES:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_EXPENSES_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            expenses: response.data,
            error: "",
          }
        : {
            ...state,
            expenses: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_EXPENSES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_EXPENSE:
      return {
        ...state,
        loading: true,
        error: "",
        expenses: [],
      };
    case Actions.API_SAVE_EXPENSE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_EXPENSE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };
    //#endregion
    //#region BANK MOVEMENTS
    case Actions.API_GET_BANK_MOVEMENTS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_BANK_MOVEMENTS_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            banks_movements: response.data,
            error: "",
          }
        : {
            ...state,
            banks_movements: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_BANK_MOVEMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_BANK_MOVEMENT:
      return {
        ...state,
        loading: true,
        error: "",
        banks_movements: [],
      };
    case Actions.API_SAVE_BANK_MOVEMENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_BANK_MOVEMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_BANK_MOVEMENT:
      return {
        ...state,
        loading: true,
        error: "",
        banks_movements: [],
      };
    case Actions.API_DELETE_BANK_MOVEMENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_BANK_MOVEMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };
    //#endregion
    //#region INTERBANK MOVEMENTS
    case Actions.API_SAVE_INTER_BANK_MOVEMENT:
      return {
        ...state,
        loading: true,
        error: "",
        banks_movements: [],
      };
    case Actions.API_SAVE_INTER_BANK_MOVEMENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_INTER_BANK_MOVEMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion

    //#region ALLOCATIONS
    case Actions.API_GET_ALLOCATIONS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_ALLOCATIONS_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            allocations: response.data,
            error: "",
          }
        : {
            ...state,
            allocations: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_ALLOCATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    //#endregion
    //#region DISTRIBUTIONS
    case Actions.API_GET_DISTRIBUTIONS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_DISTRIBUTIONS_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            distributions: response.data,
            error: "",
          }
        : {
            ...state,
            distributions: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_DISTRIBUTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_GET_DISTRIBUTION_PARTNERS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_DISTRIBUTION_PARTNERS_SUCCESS:
      return getResponseAPI(action, state, "distribution_partners");
    case Actions.API_GET_DISTRIBUTION_PARTNERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_GET_DISTRIBUTION_PARTNER_BY_ID:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_DISTRIBUTION_PARTNER_BY_ID_SUCCESS:
      return getResponseAPI(action, state, "selected_distribution_by_partner");
    case Actions.API_GET_DISTRIBUTION_PARTNER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
        selected_distribution_by_partner: {},
      };

    case Actions.SET_DISTRIBUTION_PARTNER_BY_ID:
      console.log("SET", state.selected_distribution_by_partner);
      console.log("SET DATA", action.payload);
      return {
        ...state,
        selected_distribution_by_partner: {
          ...state.selected_distribution_by_partner,
          ...action.payload,
        },
      };
    //#endregion
    //#region DISTRIBUTIONS PORTFOLIO
    case Actions.API_GET_DISTRIBUTIONS_PORTFOLIO:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_DISTRIBUTIONS_PORTFOLIO_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            distributions_portfolio: response.data,
            error: "",
          }
        : {
            ...state,
            distributions_portfolio: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_DISTRIBUTIONS_PORTFOLIO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_DISTRIBUTIONS_PORTFOLIO:
      return {
        ...state,
        loading: true,
        error: "",
        distributions_portfolio: [],
      };
    case Actions.API_SAVE_DISTRIBUTIONS_PORTFOLIO_SUCCESS:
      return getResponseAPI(action, state, "success");
    case Actions.API_SAVE_DISTRIBUTIONS_PORTFOLIO_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_DISTRIBUTIONS_PORTFOLIO:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_DISTRIBUTIONS_PORTFOLIO_SUCCESS:
      return getResponseAPI(action, state, "success");
    case Actions.API_DELETE_DISTRIBUTIONS_PORTFOLIO_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_GET_DISTRIBUTION_PORTFOLIO_BY_ID:
      return {
        ...loadinDataState(state),
        selected_payorder: null,
      };
    case Actions.API_GET_DISTRIBUTION_PORTFOLIO_BY_ID_SUCCESS:
      return getResponseAPI(action, state, "selected_distribution_portfolio");
    case Actions.API_GET_DISTRIBUTION_PORTFOLIO_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
        selected_payorder: null,
      };

    //#endregion
    //#region DISTRIBUTION PORTFOLIO WALLET
    case Actions.API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET:
      return {
        ...state,
        loading: true,
        error: "",
        distributions_portfolio_wallets: [],
      };
    case Actions.API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET_SUCCESS:
      return getResponseAPI(action, state, "success");
    case Actions.API_SAVE_DISTRIBUTIONS_PORTFOLIO_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion

    //#region YEAR MONITORING
    case Actions.API_GET_YEAR_MONITORING:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_YEAR_MONITORING_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            year_monitoring: response.data,
            error: "",
          }
        : {
            ...state,
            year_monitoring: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_YEAR_MONITORING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_GET_MONITORING_CALC:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_MONITORING_CALC_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            last_calculated_monitoring: response.data,
            error: "",
          }
        : {
            ...state,
            last_calculated_monitoring: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_MONITORING_CALC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    //#endregion

    //#region INVOICES
    case Actions.API_GET_INVOICES:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_INVOICES_SUCCESS:
      return getResponseAPI(action, state, "invoices");
    case Actions.API_GET_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case Actions.API_SAVE_INVOICE:
      return {
        ...state,
        loading: true,
        error: "",
        invoices: [],
      };
    case Actions.API_SAVE_INVOICE_SUCCESS:
      return getResponseAPI(action, state, "success");
    case Actions.API_SAVE_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };
    case Actions.API_UPDATE_INVOICE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_INVOICE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion
    //#region ACOUNTS RECEIVABLE
    case Actions.API_GET_ACCOUNTS_RECEIVABLE:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_ACCOUNTS_RECEIVABLE_SUCCESS:
      return getResponseAPI(action, state, "accounts_receivable");
    case Actions.API_GET_ACCOUNTS_RECEIVABLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_ACCOUNTS_RECEIVABLE:
      return {
        ...state,
        loading: true,
        error: "",
        accounts_receivable: [],
      };
    case Actions.API_SAVE_ACCOUNTS_RECEIVABLE_SUCCESS:
      return getResponseAPI(action, state, "success");
    case Actions.API_SAVE_ACCOUNTS_RECEIVABLE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_GET_ACCOUNT_RECEIVABLE:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_ACCOUNT_RECEIVABLE_SUCCESS:
      return getResponseAPI(action, state, "selected_account_receivable");
    case Actions.API_GET_ACCOUNT_RECEIVABLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_ACCOUNT_RECEIVABLE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_ACCOUNT_RECEIVABLE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_ACCOUNT_RECEIVABLE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_ACCOUNT_RECEIVABLE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_ACCOUNT_RECEIVABLE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_ACCOUNT_RECEIVABLE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion
    //#region INVOICE PAYMENT
    case Actions.API_GET_INVOICE_PAYMENTS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_INVOICE_PAYMENTS_SUCCESS:
      return getResponseAPI(action, state, "invoices_payments");
    case Actions.API_GET_INVOICE_PAYMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case Actions.API_GET_INVOICE_PAYMENT:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_INVOICE_PAYMENT_SUCCESS:
      console.log("action", action);
      return getResponseAPI(action, state, "selected_account_receivable");
    case Actions.API_GET_INVOICE_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case Actions.API_SAVE_INVOICE_PAYMENT:
      return {
        ...state,
        loading: true,
        error: "",
        invoices_payments: [],
      };
    case Actions.API_SAVE_INVOICE_PAYMENT_SUCCESS:
      return getResponseAPI(action, state, "success");
    case Actions.API_SAVE_INVOICE_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_INVOICE_PAYMENT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_INVOICE_PAYMENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_INVOICE_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion
    //#region PAYABLE ACCOUNT
    case Actions.API_GET_PAYABLE_ACCOUNTS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_PAYABLE_ACCOUNTS_SUCCESS:
      return getResponseAPI(action, state, "payable_accounts");
    case Actions.API_GET_PAYABLE_ACCOUNTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_PAYABLE_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: "",
        payable_accounts: [],
      };
    case Actions.API_SAVE_PAYABLE_ACCOUNT_SUCCESS:
      return getResponseAPI(action, state, "success");
    case Actions.API_SAVE_PAYABLE_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_GET_PAYABLE_ACCOUNT:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_PAYABLE_ACCOUNT_SUCCESS:
      console.log(action);
      return getResponseAPI(action, state, "selected_account_payable");
    case Actions.API_GET_PAYABLE_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_GET_PAYABLE_ACCOUNT_PAYMENT:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_PAYABLE_ACCOUNT_PAYMENT_SUCCESS:
      return getResponseAPI(action, state, "");
    case Actions.API_GET_PAYABLE_ACCOUNT_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_PAYABLE_ACCOUNT_PAYMENT:
      return {
        ...state,
        loading: true,
        error: "",
        payable_accounts: [],
        last_payorder_saved: null,
      };
    case Actions.API_SAVE_PAYABLE_ACCOUNT_PAYMENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            last_payorder_saved: response?.db?.odpid,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_PAYABLE_ACCOUNT_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
        last_payorder_saved: null,
      };

    case Actions.API_DELETE_PAYABLE_ACCOUNT_PAYMENT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_PAYABLE_ACCOUNT_PAYMENT_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_PAYABLE_ACCOUNT_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    //#endregion
    //#region PAYROLL
    case Actions.API_GET_PAYROLLS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_PAYROLLS_SUCCESS:
      return getResponseAPI(action, state, "payrolls");
    case Actions.API_GET_PAYROLLS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_GET_PAYROLL_BY_ID:
      return {
        ...state,
        loading: true,
        selected_payroll: null,
        error: "",
        success: false,
      };
    case Actions.API_GET_PAYROLL_BY_ID_SUCCESS:
      return getResponseAPI(action, state, "selected_payroll");
    case Actions.API_GET_PAYROLL_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    //#endregion
    //#region  DASHBOARD
    case Actions.API_GET_DASHBOARD:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_DASHBOARD_SUCCESS:
      return getResponseAPI(action, state, "dashboard");
    case Actions.API_GET_DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    //#endregion
    //#region  WITHHOLDINGS
    case Actions.API_GET_WITHHOLDINGS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_WITHHOLDINGS_SUCCESS:
      return getResponseAPI(action, state, "withholdings");
    case Actions.API_GET_WITHHOLDINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    //#endregion
    //#region REFUNDS
    case Actions.API_GET_REFUNDS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_REFUNDS_SUCCESS:
      return getResponseAPI(action, state, "refunds");
    case Actions.API_GET_REFUNDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_REFUND:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_SAVE_REFUND_SUCCESS:
      return getResponseAPI(action, state, "success");
    case Actions.API_SAVE_REFUND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    //#endregion
    //#region REFUNDS
    case Actions.API_GET_DOCUMENTS:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_DOCUMENTS_SUCCESS:
      return getResponseAPI(action, state, "documents_received");
    case Actions.API_GET_DOCUMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    //#endregion

    //#region BUDGET
    case Actions.API_GET_BUDGETS:
      return {
        ...loadinDataState(state, "selected_budget"),
      };
    case Actions.API_GET_BUDGETS_SUCCESS:
      return getResponseAPI(action, state, "budgets");
    case Actions.API_GET_BUDGETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_BUDGET:
      return {
        ...state,
        loading: true,
        error: "",
        budgets: [],
      };
    case Actions.API_SAVE_BUDGET_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_BUDGET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_BUDGET:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_BUDGET_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_BUDGET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_BUDGET:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_BUDGET_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_BUDGET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_GET_BUDGET_DETAIL:
      return {
        ...loadinDataState(state),
      };
    case Actions.API_GET_BUDGET_DETAIL_SUCCESS:
      return getResponseAPI(action, state, "selected_budget");
    case Actions.API_GET_BUDGET_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    //#endregion
    case PURGE:
      console.log("PURGE IN REDUCER");
      return initialState;
    default:
      //console.log("default",action);
      return state;
  }
};

export default reducer_api2;
