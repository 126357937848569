import * as Actions from "./actions";

const initialState = {
  employees: [],
  employee_payment_types: [],
  jobs: [],
  loading: false,
  error: "",
  success: false,
};
let response = "";

const reducer_persona = (state = initialState, action) => {
  response = "";
  switch (action.type) {
    //#region  EMPLOYEES
    case Actions.API_GET_EMPLOYEES:
      return {
        ...state,
        loading: true,
        error: "",
        success: false,
      };
    case Actions.API_GET_EMPLOYEES_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            employees: response.data,
            error: "",
          }
        : {
            ...state,
            employees: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_EMPLOYEES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_EMPLOYEE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_SAVE_EMPLOYEE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_EMPLOYEE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_EMPLOYEE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_EMPLOYEE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_EMPLOYEE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };
    //#endregion
    
    //#region JOBS
    case Actions.API_GET_JOBS:
      return {
        ...state,
        loading: true,
        error: "",
        success: false,
      };
    case Actions.API_GET_JOBS_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            jobs: response.data,
            error: "",
          }
        : {
            ...state,
            jobs: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_JOBS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_JOB:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_SAVE_JOB_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_JOB_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_JOB:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_JOB_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_JOB_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_JOB:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_JOB_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_JOB_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };
    //#endregion
    //#region  EMPLOYEE_PAYMENT_TYPES
    case Actions.API_GET_EMPLOYEE_PAYMENT_TYPES:
      return {
        ...state,
        loading: true,
        error: "",
        success: false,
      };
    case Actions.API_GET_EMPLOYEE_PAYMENT_TYPES_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            employee_payment_types: response.data,
            error: "",
          }
        : {
            ...state,
            employee_payment_types: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_EMPLOYEE_PAYMENT_TYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_EMPLOYEE_PAYMENT_TYPE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_SAVE_EMPLOYEE_PAYMENT_TYPE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_EMPLOYEE_PAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_EMPLOYEE_PAYMENT_TYPE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_EMPLOYEE_PAYMENT_TYPE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_EMPLOYEE_PAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_EMPLOYEE_PAYMENT_TYPE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_EMPLOYEE_PAYMENT_TYPE_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_EMPLOYEE_PAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };
    //#endregion
    default:
      return state;
  }
};

export default reducer_persona;
