/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* API*/

export const API_SAVE_BANK = "API_SAVE_BANK";
export const API_SAVE_BANK_SUCCESS = "API_SAVE_BANK_SUCCESS";
export const API_SAVE_BANK_ERROR = "API_SAVE_BANK_ERROR";
export const API_UPDATE_BANK = "API_UPDATE_BANK";
export const API_UPDATE_BANK_SUCCESS = "API_UPDATE_BANK_SUCCESS";
export const API_UPDATE_BANK_ERROR = "API_UPDATE_BANK_ERROR";
export const API_DELETE_BANK = "API_DELETE_BANK";
export const API_DELETE_BANK_SUCCESS = "API_DELETE_BANK_SUCCESS";
export const API_DELETE_BANK_ERROR = "API_DELETE_BANK_ERROR";

export const API_SAVE_DEPARTMENT = "API_SAVE_DEPARTMENT";
export const API_SAVE_DEPARTMENT_SUCCESS = "API_SAVE_DEPARTMENT_SUCCESS";
export const API_SAVE_DEPARTMENT_ERROR = "API_SAVE_DEPARTMENT_ERROR";
export const API_UPDATE_DEPARTMENT = "API_UPDATE_DEPARTMENT";
export const API_UPDATE_DEPARTMENT_SUCCESS = "API_UPDATE_DEPARTMENT_SUCCESS";
export const API_UPDATE_DEPARTMENT_ERROR = "API_UPDATE_DEPARTMENT_ERROR";
export const API_DELETE_DEPARTMENT = "API_DELETE_DEPARTMENT";
export const API_DELETE_DEPARTMENT_SUCCESS = "API_DELETE_DEPARTMENT_SUCCESS";
export const API_DELETE_DEPARTMENT_ERROR = "API_DELETE_DEPARTMENT_ERROR";



export const API_GET_CURRENCY = "API_GET_CURRENCY";
export const API_GET_CURRENCY_SUCCESS = "API_GET_CURRENCY_SUCCESS";
export const API_GET_CURRENCY_ERROR = "API_GET_CURRENCY_ERROR";
export const API_SAVE_CURRENCY = "API_SAVE_CURRENCY";
export const API_SAVE_CURRENCY_SUCCESS = "API_SAVE_CURRENCY_SUCCESS";
export const API_SAVE_CURRENCY_ERROR = "API_SAVE_CURRENCY_ERROR";
export const API_UPDATE_CURRENCY = "API_UPDATE_CURRENCY";
export const API_UPDATE_CURRENCY_SUCCESS = "API_UPDATE_CURRENCY_SUCCESS";
export const API_UPDATE_CURRENCY_ERROR = "API_UPDATE_CURRENCY_ERROR";
export const API_DELETE_CURRENCY = "API_DELETE_CURRENCY";
export const API_DELETE_CURRENCY_SUCCESS = "API_DELETE_CURRENCY_SUCCESS";
export const API_DELETE_CURRENCY_ERROR = "API_DELETE_CURRENCY_ERROR";

export const API_SAVE_EXPENSE_CATEGORY = "API_SAVE_EXPENSE_CATEGORY";
export const API_SAVE_EXPENSE_CATEGORY_SUCCESS =
  "API_SAVE_EXPENSE_CATEGORY_SUCCESS";
export const API_SAVE_EXPENSE_CATEGORY_ERROR =
  "API_SAVE_EXPENSE_CATEGORY_ERROR";
export const API_UPDATE_EXPENSE_CATEGORY = "API_UPDATE_EXPENSE_CATEGORY";
export const API_UPDATE_EXPENSE_CATEGORY_SUCCESS =
  "API_UPDATE_EXPENSE_CATEGORY_SUCCESS";
export const API_UPDATE_EXPENSE_CATEGORY_ERROR =
  "API_UPDATE_EXPENSE_CATEGORY_ERROR";
export const API_DELETE_EXPENSE_CATEGORY = "API_DELETE_EXPENSE_CATEGORY";
export const API_DELETE_EXPENSE_CATEGORY_SUCCESS =
  "API_DELETE_EXPENSE_CATEGORY_SUCCESS";
export const API_DELETE_EXPENSE_CATEGORY_ERROR =
  "API_DELETE_EXPENSE_CATEGORY_ERROR";

export const API_GET_PROVIDERS = "API_GET_PROVIDERS";
export const API_GET_PROVIDERS_SUCCESS = "API_GET_PROVIDERS_SUCCESS";
export const API_GET_PROVIDERS_ERROR = "API_GET_PROVIDERS_ERROR";
export const API_SAVE_PROVIDER = "API_SAVE_PROVIDER";
export const API_SAVE_PROVIDER_SUCCESS = "API_SAVE_PROVIDER_SUCCESS";
export const API_SAVE_PROVIDER_ERROR = "API_SAVE_PROVIDER_ERROR";
export const API_UPDATE_PROVIDER = "API_UPDATE_PROVIDEER";
export const API_UPDATE_PROVIDER_SUCCESS = "API_UPDATE_PROVIDEER_SUCCESS";
export const API_UPDATE_PROVIDER_ERROR = "API_UPDATE_PROVIDEER_ERROR";
export const API_DELETE_PROVIDER = "API_DELETE_PROVIDER";
export const API_DELETE_PROVIDER_SUCCESS = "API_DELETE_PROVIDER_SUCCESS";
export const API_DELETE_PROVIDER_ERROR = "API_DELETE_PROVIDER_ERROR";

export const API_SAVE_CLIENT = "API_SAVE_CLIENT";
export const API_SAVE_CLIENT_SUCCESS = "API_SAVE_CLIENT_SUCCESS";
export const API_SAVE_CLIENT_ERROR = "API_SAVE_CLIENT_ERROR";

export const API_GET_EMPLOYEES = "API_GET_CLIENTS";
export const API_GET_EMPLOYEES_SUCCESS = "API_GET_CLIENTS_SUCCESS";
export const API_GET_EMPLOYEES_ERROR = "API_GET_CLIENTS_ERROR";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./api/actions";
