export const API_GET_PAYROLLS = "API_GET_PAYROLLS";
export const API_GET_PAYROLLS_SUCCESS = "API_GET_PAYROLLS_SUCCESS";
export const API_GET_PAYROLLS_FAIL = "API_GET_PAYROLLS_FAIL";
export const getPayrolls = (user) => ({
  type: API_GET_PAYROLLS,
  payload: {
    request: {
      method: "GET",
      url: "/payroll",
      data: {
        user,
      },
    },
  },
});

export const API_GET_PAYROLL_BY_ID = "API_GET_PAYROLL_BY_ID";
export const API_GET_PAYROLL_BY_ID_SUCCESS = "API_GET_PAYROLL_BY_ID_SUCCESS";
export const API_GET_PAYROLL_BY_ID_FAIL = "API_GET_PAYROLL_BY_ID_FAIL";
export const getPayrollById = (payroll) => ({
  type: API_GET_PAYROLL_BY_ID,
  payload: {
    request: {
      method: "GET",
      url: "/payroll/" + payroll.correlative,
      data: {},
    },
  },
});

export const API_SAVE_PAYROLL = "API_SAVE_PAYROLL";
export const API_SAVE_PAYROLL_SUCCESS = "API_SAVE_PAYROLL_SUCCESS";
export const API_SAVE_PAYROLL_FAIL = "API_SAVE_PAYROLL_FAIL";
export const savePayroll = (payroll) => ({
  type: API_SAVE_PAYROLL,
  payload: {
    request: {
      method: "POST",
      url: "/payroll",
      data: payroll,
    },
  },
});

export const API_UPDATE_PAYROLL = "API_UPDATE_PAYROLL";
export const API_UPDATE_PAYROLL_SUCCESS = "API_UPDATE_PAYROLL_SUCCESS";
export const API_UPDATE_PAYROLL_FAIL = "API_UPDATE_PAYROLL_FAIL";
export const updatePayroll = (payroll) => ({
  type: API_UPDATE_PAYROLL,
  payload: {
    request: {
      method: "PUT",
      url: "/payroll/" + payroll.correlative,
      data: payroll,
    },
  },
});

export const API_DELETE_PAYROLL = "API_DELETE_PAYROLL";
export const API_DELETE_PAYROLL_SUCCESS = "API_DELETE_PAYROLL_SUCCESS";
export const API_DELETE_PAYROLL_FAIL = "API_DELETE_PAYROLL_FAIL";
export const deletePayroll = (payroll) => ({
  type: API_DELETE_PAYROLL,
  payload: {
    request: {
      method: "DELETE",
      url: "/payroll/" + payroll.correlative,
      data: payroll,
    },
  },
});
