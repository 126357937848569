import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";
import sagas from "./sagas";

import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";

import { composeWithDevTools } from "redux-devtools-extension";

import { persistStore } from "redux-persist";

// import { handleRequests } from '@redux-requests/core';
// import { createDriver } from '@redux-requests/axios'; // or another driver

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  withCredentials: true,
});

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const axiosMiddlewareConfig = {
  interceptors: {
    request: [
      {
        success: function ({ getState, dispatch, getSourceAction }, req) {
          //console.log(req);
          return req;
        },
      },
    ],
  },
};

const store = createStore(
  reducers,
  {},
  composeWithDevTools(
    applyMiddleware(
      ...middlewares,
      axiosMiddleware(client, axiosMiddlewareConfig)
    )
  )
);

const persistor = persistStore(store);

// export function configureStore(initialState) {
sagaMiddleware.run(sagas);

//   // if (module.hot) {
//   //   module.hot.accept("./reducers", () => {
//   //     const nextRootReducer = require("./reducers");
//   //     store.replaceReducer(nextRootReducer);
//   //   });
//   // }

//   return { store, persistor };
// }

export { store, persistor };
