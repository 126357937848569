import * as Actions from "./actions";

const initialState = {
  payrolls: [],
  selected_payroll: null,
  loading: false,
  error: "",
  success: false,
};
let response = "";

const reducer_payroll = (state = initialState, action) => {
  response = "";
  switch (action.type) {
    //#region  PAYROLL
    case Actions.API_GET_PAYROLLS:
      return {
        ...state,
        loading: true,
        error: "",
        success: false,
      };
    case Actions.API_GET_PAYROLLS_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            payrolls: response.data,
            error: "",
          }
        : {
            ...state,
            payrolls: [],
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_PAYROLLS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case Actions.API_GET_PAYROLL_BY_ID:
      return {
        ...state,
        loading: true,
        selected_payroll: null,
        error: "",
        success: false,
      };
    case Actions.API_GET_PAYROLL_BY_ID_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            selected_payroll: response.data,
            error: "",
          }
        : {
            ...state,
            selected_payroll: null,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_GET_PAYROLL_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case Actions.API_SAVE_PAYROLL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_SAVE_PAYROLL_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_SAVE_PAYROLL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_UPDATE_PAYROLL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_UPDATE_PAYROLL_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_UPDATE_PAYROLL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };

    case Actions.API_DELETE_PAYROLL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Actions.API_DELETE_PAYROLL_SUCCESS:
      response = action.payload.data.response;
      return response?.db?.typ === "success"
        ? {
            ...state,
            loading: false,
            success: true,
            error: "",
          }
        : {
            ...state,
            success: false,
            loading: false,
            error: response?.db?.msg ? response?.db?.msg : "Error desconocido",
          };
    case Actions.API_DELETE_PAYROLL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error.message,
      };
    //#endregion

    default:
      return state;
  }
};

export default reducer_payroll;
