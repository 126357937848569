import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";

import settings from "./settings/reducer";
import menu from "./menu/reducer";
//import authUser from "./auth/reducer";
import api from "./api/reducer";
import persona from "./persona/reducer";
import payroll from "./payroll/reducer";
import api2 from "./api2/reducers";
//import auth2 from "./auth2/reducer";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["api", "api2", "payroll", "settings", "persona"],
  //stateReconciler: hardSet,
};

const api2PersistConfig = {
  key: "api2",
  storage: storage,
  //whitelist: ["user_logged", "session"],
  blacklist: ["loading", "error", "success"],
  stateReconciler: hardSet,
};

const rootReducer = combineReducers({
  menu,
  settings,
  api,
  persona,
  payroll,
  api2: persistReducer(api2PersistConfig, api2),
});

export default persistReducer(rootPersistConfig, rootReducer);
