export const API_GET_EMPLOYEES = "API_GET_EMPLOYEES";
export const API_GET_EMPLOYEES_SUCCESS = "API_GET_EMPLOYEES_SUCCESS";
export const API_GET_EMPLOYEES_FAIL = "API_GET_EMPLOYEES_FAIL";
export const getEmployees = (user) => ({
  type: API_GET_EMPLOYEES,
  payload: {
    request: {
      method: "GET",
      url: "/employee",
      data: {
        user,
      },
    },
  },
});

export const API_SAVE_EMPLOYEE = "API_SAVE_EMPLOYEE";
export const API_SAVE_EMPLOYEE_SUCCESS = "API_SAVE_EMPLOYEE_SUCCESS";
export const API_SAVE_EMPLOYEE_FAIL = "API_SAVE_EMPLOYEE_FAIL";
export const saveEmployee = (employee) => ({
  type: API_SAVE_EMPLOYEE,
  payload: {
    request: {
      method: "POST",
      url: "/employee",
      data: employee,
    },
  },
});

export const API_UPDATE_EMPLOYEE = "API_UPDATE_EMPLOYEE";
export const API_UPDATE_EMPLOYEE_SUCCESS = "API_UPDATE_EMPLOYEE_SUCCESS";
export const API_UPDATE_EMPLOYEE_FAIL = "API_UPDATE_EMPLOYEE_FAIL";
export const updateEmployee = (employee) => ({
  type: API_SAVE_EMPLOYEE,
  payload: {
    request: {
      method: "PUT",
      url: "/employee/" + employee.correlative,
      data: employee,
    },
  },
});

export const API_DELETE_EMPLOYEE = "API_DELETE_EMPLOYEE";
export const API_DELETE_EMPLOYEE_SUCCESS = "API_DELETE_EMPLOYEE_SUCCESS";
export const API_DELETE_EMPLOYEE_FAIL = "API_DELETE_EMPLOYEE_FAIL";
export const deleteEmployee = (employee) => ({
  type: API_DELETE_EMPLOYEE,
  payload: {
    request: {
      method: "DELETE",
      url: "/employee/" + employee.correlative,
      data: employee,
    },
  },
});

export const API_GET_JOBS = "API_GET_JOBS";
export const API_GET_JOBS_SUCCESS = "API_GET_JOBS_SUCCESS";
export const API_GET_JOBS_FAIL = "API_GET_JOBS_FAIL";
export const getJobs = (user) => ({
  type: API_GET_JOBS,
  payload: {
    request: {
      method: "GET",
      url: "/job",
      data: {
        user,
      },
    },
  },
});

export const API_SAVE_JOB = "API_SAVE_JOB";
export const API_SAVE_JOB_SUCCESS = "API_SAVE_JOB_SUCCESS";
export const API_SAVE_JOB_FAIL = "API_SAVE_JOB_FAIL";
export const saveJob = (job) => ({
  type: API_SAVE_JOB,
  payload: {
    request: {
      method: "POST",
      url: "/job",
      data: job,
    },
  },
});

export const API_UPDATE_JOB = "API_UPDATE_JOB";
export const API_UPDATE_JOB_SUCCESS = "API_UPDATE_JOB_SUCCESS";
export const API_UPDATE_JOB_FAIL = "API_UPDATE_JOB_FAIL";
export const updateJob = (job) => ({
  type: API_SAVE_JOB,
  payload: {
    request: {
      method: "PUT",
      url: "/job/" + job.correlative,
      data: job,
    },
  },
});

export const API_DELETE_JOB = "API_DELETE_JOB";
export const API_DELETE_JOB_SUCCESS = "API_DELETE_JOB_SUCCESS";
export const API_DELETE_JOB_FAIL = "API_DELETE_JOB_FAIL";
export const deleteJob = (job) => ({
  type: API_DELETE_JOB,
  payload: {
    request: {
      method: "DELETE",
      url: "/job/" + job.correlative,
      data: job,
    },
  },
});

export const API_GET_EMPLOYEE_PAYMENT_TYPES = "API_GET_EMPLOYEE_PAYMENT_TYPES";
export const API_GET_EMPLOYEE_PAYMENT_TYPES_SUCCESS =
  "API_GET_EMPLOYEE_PAYMENT_TYPES_SUCCESS";
export const API_GET_EMPLOYEE_PAYMENT_TYPES_FAIL =
  "API_GET_EMPLOYEE_PAYMENT_TYPES_FAIL";
export const getEmployeePaymentTypes = (user) => ({
  type: API_GET_EMPLOYEE_PAYMENT_TYPES,
  payload: {
    request: {
      method: "GET",
      url: "/payment",
      data: {
        user,
      },
    },
  },
});

export const API_SAVE_EMPLOYEE_PAYMENT_TYPE = "API_SAVE_EMPLOYEE_PAYMENT_TYPE";
export const API_SAVE_EMPLOYEE_PAYMENT_TYPE_SUCCESS =
  "API_SAVE_EMPLOYEE_PAYMENT_TYPE_SUCCESS";
export const API_SAVE_EMPLOYEE_PAYMENT_TYPE_FAIL =
  "API_SAVE_EMPLOYEE_PAYMENT_TYPE_FAIL";
export const saveEmployeePaymentType = (employee_type_payment) => ({
  type: API_SAVE_EMPLOYEE_PAYMENT_TYPE,
  payload: {
    request: {
      method: "POST",
      url: "/payment",
      data: employee_type_payment,
    },
  },
});

export const API_UPDATE_EMPLOYEE_PAYMENT_TYPE =
  "API_UPDATE_EMPLOYEE_PAYMENT_TYPE";
export const API_UPDATE_EMPLOYEE_PAYMENT_TYPE_SUCCESS =
  "API_UPDATE_EMPLOYEE_PAYMENT_TYPE_SUCCESS";
export const API_UPDATE_EMPLOYEE_PAYMENT_TYPE_FAIL =
  "API_UPDATE_EMPLOYEE_PAYMENT_TYPE_FAIL";
export const updateEmployeePaymentType = (employee_type_payment) => ({
  type: API_SAVE_EMPLOYEE_PAYMENT_TYPE,
  payload: {
    request: {
      method: "PUT",
      url: "/payment/" + employee_type_payment.correlative,
      data: employee_type_payment,
    },
  },
});

export const API_DELETE_EMPLOYEE_PAYMENT_TYPE =
  "API_DELETE_EMPLOYEE_PAYMENT_TYPE";
export const API_DELETE_EMPLOYEE_PAYMENT_TYPE_SUCCESS =
  "API_DELETE_EMPLOYEE_PAYMENT_TYPE_SUCCESS";
export const API_DELETE_EMPLOYEE_PAYMENT_TYPE_FAIL =
  "API_DELETE_EMPLOYEE_PAYMENT_TYPE_FAIL";
export const deleteEmployeePaymentType = (employee_type_payment) => ({
  type: API_DELETE_EMPLOYEE_PAYMENT_TYPE,
  payload: {
    request: {
      method: "DELETE",
      url: "/payment/" + employee_type_payment.correlative,
      data: employee_type_payment,
    },
  },
});
