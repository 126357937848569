import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  API_GET_CURRENCY,
  API_GET_PROVIDERS,
  API_GET_EMPLOYEES,
  API_SAVE_PROVIDER,
  API_UPDATE_PROVIDER,
  API_DELETE_PROVIDER,
  API_SAVE_BANK,
  API_UPDATE_BANK,
  API_DELETE_BANK,
  API_SAVE_CURRENCY,
  API_DELETE_CURRENCY,
  API_UPDATE_CURRENCY,
} from "../actions";

import {
  getProvidersError,
  getProvidersSuccess,
  getCurrenciesSuccess,
  getCurrenciesError,
  updateProviderSuccess,
  saveProviderError,
  saveProviderSuccess,
  updateProviderError,
  deleteProviderError,
  deleteProviderSuccess,
  saveBankError,
  saveBankSuccess,
  updateBankError,
  updateBankSuccess,
  deleteBankError,
  deleteBankSuccess,

  saveCurrencyError,
  saveCurrencySuccess,
  updateCurrencyError,
  updateCurrencySuccess,
  deleteCurrencyError,
  deleteCurrencySuccess,
  getEmployeesError,
  getEmployeesSuccess,
} from "./actions";

//#region  BANKS

export function* watchSaveBank() {
  yield takeEvery(API_SAVE_BANK, saveBank);
}
export function* watchUpdateBank() {
  yield takeEvery(API_UPDATE_BANK, updateBank);
}
export function* watchDeleteBank() {
  yield takeEvery(API_DELETE_BANK, deleteBank);
}

export function* saveBank({ payload }) {
  const { bank } = payload;
  try {
    const api_req = yield call(() =>
      axios.post(process.env.REACT_APP_API_URL + "/Bank", bank, {
        withCredentials: true,
      })
    );
    let response = api_req.data.response;
    if (response.db.typ === "error") {
      yield put(saveBankError(response.db.msg));
    } else {
      yield put(saveBankSuccess(response.data));
    }
  } catch (error) {
    yield put(saveBankError(error.message));
  }
}
export function* updateBank({ payload }) {
  const { bank } = payload;
  console.log("UPDATE BANK", bank);
  try {
    const api_req = yield call(() =>
      axios.put(
        process.env.REACT_APP_API_URL + "/bank/" + bank.correlative,
        bank,
        {
          withCredentials: true,
        }
      )
    );

    let response = api_req.data.response;
    console.log(response.db.typ);
    if (response.db.typ === "error") {
      yield put(updateBankError(response.db.msg));
    } else {
      yield put(updateBankSuccess(response.data));
    }
  } catch (error) {
    yield put(updateBankError(error.message));
  }
}
export function* deleteBank({ payload }) {
  const { bank } = payload;
  try {
    const api_req = yield call(() =>
      axios.delete(
        process.env.REACT_APP_API_URL + "/bank/" + bank.correlative,

        {
          withCredentials: true,
        }
      )
    );

    let response = api_req.data.response;
    console.log(response.db.typ);
    if (response.db.typ === "error") {
      yield put(deleteBankError(response.db.msg));
    } else {
      yield put(deleteBankSuccess(response.data));
    }
  } catch (error) {
    yield put(deleteBankError(error.message));
  }
}
//#endregion


//#region  CURRENCIES
export function* watchGetCurrencies() {
  yield takeEvery(API_GET_CURRENCY, getCurrencies);
}
export function* watchSaveCurrency() {
  yield takeEvery(API_SAVE_CURRENCY, saveCurrency);
}
export function* watchUpdateCurrency() {
  yield takeEvery(API_UPDATE_CURRENCY, updateCurrency);
}
export function* watchDeleteCurrency() {
  yield takeEvery(API_DELETE_CURRENCY, deleteCurrency);
}
export function* getCurrencies({ payload }) {
  try {
    const api_req = yield call(() =>
      axios.get(process.env.REACT_APP_API_URL + "/currency", {
        withCredentials: true,
      })
    );

    let response = api_req.data.response;

    if (response.db.typ === "error") {
      yield put(getCurrenciesError(response.db.msg));
    } else {
      console.log(response.data);
      yield put(getCurrenciesSuccess(response.data));
    }
  } catch (error) {
    console.log(error.message);
    yield put(getCurrenciesError(error.message));
  }
}
export function* saveCurrency({ payload }) {
  const { currency } = payload;
  console.log(currency);
  try {
    const api_req = yield call(() =>
      axios.post(process.env.REACT_APP_API_URL + "/currency", currency, {
        withCredentials: true,
      })
    );
    let response = api_req.data.response;
    if (response?.db?.typ === "success") {
      yield put(saveCurrencySuccess(response.data));
    } else {
      yield put(saveCurrencyError(response?.db?.msg));
    }
  } catch (error) {
    yield put(saveCurrencyError(error));
  }
}
export function* updateCurrency({ payload }) {
  const { currency } = payload;
  try {
    const api_req = yield call(() =>
      axios.put(
        process.env.REACT_APP_API_URL + "/currency/" + currency.correlative,
        currency,
        {
          withCredentials: true,
        }
      )
    );

    let response = api_req.data.response;
    console.log(response.db.typ);
    if (response.db.typ === "error") {
      yield put(updateCurrencyError(response.db.msg));
    } else {
      yield put(updateCurrencySuccess(response.data));
    }
  } catch (error) {
    yield put(updateCurrencyError(error.message));
  }
}
export function* deleteCurrency({ payload }) {
  const { currency } = payload;
  try {
    const api_req = yield call(() =>
      axios.delete(
        process.env.REACT_APP_API_URL + "/currency/" + currency.correlative,

        {
          withCredentials: true,
        }
      )
    );

    let response = api_req.data.response;
    console.log(response.db.typ);
    if (response.db.typ === "error") {
      yield put(deleteCurrencyError(response.db.msg));
    } else {
      yield put(deleteCurrencySuccess(response.data));
    }
  } catch (error) {
    yield put(deleteCurrencyError(error.message));
  }
}
//#endregion

//#region  PROVIDERS
export function* watchGetProviders() {
  yield takeEvery(API_GET_PROVIDERS, getProviders);
}
export function* watchSaveProvider() {
  yield takeEvery(API_SAVE_PROVIDER, saveProvider);
}
export function* watchUpdateProvider() {
  yield takeEvery(API_UPDATE_PROVIDER, updateProvider);
}
export function* watchDeleteProvider() {
  yield takeEvery(API_DELETE_PROVIDER, deleteProvider);
}

export function* getProviders({ payload }) {
  console.log("recuperando providers");
  try {
    const api_req = yield call(() =>
      axios.get(
        process.env.REACT_APP_API_URL + "/provider",

        {
          withCredentials: true,
        }
      )
    );
    let response = api_req.data.response;

    if (response.db.typ === "error") {
      yield put(getProvidersError(response.db.msg));
    } else {
      yield put(getProvidersSuccess(response.data));
    }
  } catch (error) {
    yield put(getProvidersError(error.message));
  }
}
export function* saveProvider({ payload }) {
  const { provider } = payload;
  try {
    const api_req = yield call(() =>
      axios.post(process.env.REACT_APP_API_URL + "/provider", provider, {
        withCredentials: true,
      })
    );
    let response = api_req.data.response;
    if (response.db.typ === "error") {
      yield put(saveProviderError(response.db.msg));
    } else {
      yield put(saveProviderSuccess(response.data));
    }
  } catch (error) {
    yield put(saveProviderError(error.message));
  }
}
export function* updateProvider({ payload }) {
  const { provider } = payload;
  console.log("UPDATE PROVIDER", provider);
  try {
    const api_req = yield call(() =>
      axios.put(
        process.env.REACT_APP_API_URL + "/provider/" + provider.correlative,
        provider,
        {
          withCredentials: true,
        }
      )
    );

    let response = api_req.data.response;
    console.log(response.db.typ);
    if (response.db.typ === "error") {
      yield put(updateProviderError(response.db.msg));
    } else {
      yield put(updateProviderSuccess(response.data));
    }
  } catch (error) {
    yield put(updateProviderError(error.message));
  }
}
export function* deleteProvider({ payload }) {
  const { provider } = payload;
  try {
    const api_req = yield call(() =>
      axios.delete(
        process.env.REACT_APP_API_URL + "/provider/" + provider.correlative,

        {
          withCredentials: true,
        }
      )
    );

    let response = api_req.data.response;
    console.log(response.db.typ);
    if (response.db.typ === "error") {
      yield put(deleteProviderError(response.db.msg));
    } else {
      yield put(deleteProviderSuccess(response.data));
    }
  } catch (error) {
    yield put(deleteProviderError(error.message));
  }
}
//#endregion

/* CLIENTS */

/* EMPLOYEES */
export function* watchGetEmployees() {
  yield takeEvery(API_GET_EMPLOYEES, getEmployees);
}
export function* getEmployees() {
  try {
    const api_req = yield call(() =>
      axios.get(
        process.env.REACT_APP_API_URL + "/employee",

        {
          withCredentials: true,
        }
      )
    );
    let response = api_req.data.response;

    if (response.db.typ === "error") {
      yield put(getEmployeesError(response.db.msg));
    } else {
      console.log("success");
      yield put(getEmployeesSuccess(response.data));
    }
  } catch (error) {
    yield put(getEmployeesError(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchSaveBank),
    fork(watchUpdateBank),
    fork(watchDeleteBank),

    fork(watchGetCurrencies),
    fork(watchSaveCurrency),
    fork(watchUpdateCurrency),
    fork(watchDeleteCurrency),

  

    fork(watchGetProviders),
    fork(watchSaveProvider),
    fork(watchUpdateProvider),
    fork(watchDeleteProvider),

    fork(watchGetEmployees),
  ]);
}
